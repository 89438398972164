import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

export const useGetCommonApi = () => {
  const userInfo = useRecoilValue(loginUser);

  const {
    data: semesterData,
    isLoading: semesterIsLoading,
    isError: semesterIsError,
  } = useQuery({
    queryKey: ['semesterInformation', userInfo],
    queryFn: () => getDataQuery('/term?account_id=1'),
    enabled: !!userInfo,
  });

  // 대시보드 뉴스&공지사항
  const {
    data: dashboardNoticeAndNews,
    isLoading: dashboardNoticeAndNewsIsLoading,
    isError: dashboardNoticeAndNewsIsError,
  } = useQuery({
    queryKey: ['dashboardNoticeAndNews', userInfo],
    queryFn: () => getDataQuery('/help/notification/news/all'),
    enabled: !!userInfo,
  });

  // 로그인시 팝업보여주는 api
  const {
    data: noticePopupData,
    isLoading: noticePopupIsLoading,
    isError: noticePopupIsError,
  } = useQuery({
    queryKey: ['noticePopup', userInfo],
    queryFn: () => getDataQuery('/help/notification/all/popup'),
    enabled: !!userInfo,
  });

  return {
    semesterData,
    semesterIsLoading,
    semesterIsError,

    // // 대시보드 뉴스&공지사항
    dashboardNoticeAndNews,
    dashboardNoticeAndNewsIsLoading,
    dashboardNoticeAndNewsIsError,

    // 로그인시 팝업보여주는 api
    noticePopupData,
    noticePopupIsLoading,
    noticePopupIsError,
  };
};
