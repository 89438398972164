import { debounceSecond } from 'assets/constant';
import { CardHeader } from 'components/common';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useNoticeState } from 'hooks/supporting/useNoticeState';
import { usePostSupporting } from 'hooks/supporting/usePostSupporting';
import { debounce } from 'lodash';
import { BoardContent, BoardFile, BoardFixed, BoardPublic, BoardTitle } from 'pages/supporting';
import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { BoardTypeReference } from './BoardTypeReference';

export const ReferenceWrite = () => {
  const navigate = useNavigate();
  const {
    noticeState,
    handleTitleChange,
    handleContentChange,
    handlePostStateChange,
    handleTopFixFlagChange,
    handleFileChange,
    resetNoticeState,
    handlePostTypeChange,
  } = useNoticeState();

  const { writeReferenceMutation } = usePostSupporting();

  const debounceHandleSubmit = debounce(async () => {
    const formData = new FormData();
    const { fileList, thumbnail, ...boardPostData } = noticeState;
    const boardPostDataJson = JSON.stringify(boardPostData);
    const boardPostDataBlob = new Blob([boardPostDataJson], { type: 'application/json' });
    formData.append('dataRoomCreateDto', boardPostDataBlob);

    if (fileList.length > 0)
      fileList.forEach((file) => {
        formData.append('mediaList', file);
      });
    try {
      writeReferenceMutation.mutate(formData);
      navigate('/reference');
    } catch (error) {
      console.error('error', error);
    }
  }, debounceSecond);

  const handleSubmit = (e) => {
    e.preventDefault();
    debounceHandleSubmit();
  };

  useEffect(() => {
    handlePostTypeChange('DATAROOM');
    return () => {
      resetNoticeState();
    };
  }, []);

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/reference'} sublink={'/reference'} title={'Help Center'} subTitle={'지원센터'}>
          &nbsp;/ 자료실 / 자료실 등록/수정
        </BreadCrumb>
        <div className='card p-20'>
          <CardHeader noRightBorder={false} title='자료실' visibleContent={false}></CardHeader>

          <Form onSubmit={handleSubmit} action='' className='write-form'>
            {/* <BoardType value={noticeState.postType} onChange={handlePostTypeChange} /> */}
            <BoardTypeReference />
            <BoardTitle value={noticeState.title} onChange={handleTitleChange} />
            <BoardPublic value={noticeState.postState} onChange={handlePostStateChange} />
            <BoardFixed value={noticeState.topFixFlag} onChange={handleTopFixFlagChange} />
            <BoardContent value={noticeState.content} onChange={handleContentChange} />
            <BoardFile value={noticeState.fileList} onChange={handleFileChange} />

            {/* 첨부파일 */}

            <div className='d-flex align-items-center justify-content-center border-top pt-3'>
              <Button type='submit' variant='primary' className='me-2'>
                등록
              </Button>
              <Button type='button' onClick={() => navigate(-1)} variant='secondary'>
                취소
              </Button>
            </div>
          </Form>
        </div>
      </main>
    </>
  );
};
