/**
 * 사용자 역할을 확인하고 한글로 변환하여 반환
 * @param {string} role - 사용자의 역할을 나타내는 문자열
 * @returns {string} - 한글로 변환된 사용자 역할
 */
export const checkUserRole = (role) => {
  let userType = '';

  switch (role) {
    case 'STUDENT':
      userType = '학습자';
      break;
    case 'TEACHER':
      userType = '교수자';
      break;
    case 'ADMIN':
      userType = '관리자';
      break;

    default:
      userType = '유저';
      break;
  }

  return userType;
};

/**
 * 테이블의 페이지네이션된 데이터에서 각 항목의 실제 순서를 계산함
 * @param {number} index - 테이블에서의 아이템의 인덱스
 * @param {number} currentPage - 현재 페이지 번호
 * @returns {number} - 계산된 아이템의 인덱스
 */
export const accountTableIndexCalculator = (index, currentPage, topLength = 0) => {
  let itemIndex = 0;

  if (currentPage === 1) {
    itemIndex = index + 1;
  } else {
    itemIndex = (currentPage - 1) * 10 + (index + 1);
  }

  return itemIndex - topLength;
};

/**
 * 사용자 이름을 받아와서 '_' 문자를 기준으로 이름 부분만 반환하는 유틸리티 함수.
 * @param {string} name 사용자 이름으로, '_' 문자로 분리된 사용자 이름.
 * @returns {string} 사용자의 이름 부분만을 반환.
 * 만약 오류가 발생하면 '사용자'라는 문자열을 반환.
 */
export const userNameSplit = (name) => {
  let userName = '';
  try {
    userName = name.split('_')[0];
    return userName;
  } catch (error) {
    return (name = '사용자');
  }
};
