import { Link } from 'react-router-dom';

/**
 *
 * @param {number} index 이미지의 인덱스 1,2
 * @param {ReactNode} children 카드안에 들어갈 텍스트
 * @param {string} link 카드 클릭시 이동할 링크
 * @returns
 */
export function DashboardPopnoto({ index, children, link }) {
  return (
    <div className='col-3'>
      <div className='card info-card p-3 card-min'>
        {/* img-box */}
        <div className='d-flex justify-content-center align-items-center mb-3'>
          <img width={40} height={40} src={`assets//img/admin/admin_dashboard_img${index}.jpg`} alt='' />
        </div>
        <div className='text-center'>
          {/* text-box */}
          <p className='--Palette-Gray-70 fz-16 mb-3'>
            {children}
            <br />
            관리자 페이지
          </p>
          {/* <Link to={link} className='--Palette-Gray-80 fw-bold fz-18'>
            바로가기 &gt;
          </Link> */}
          <Link href={link} className='--Palette-Gray-80 fw-bold fz-18'>
            바로가기 &gt;
          </Link>
        </div>
      </div>
    </div>
  );
}
