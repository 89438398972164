import { useGetSubjectNoticeDetail } from 'hooks';
import moment from 'moment';
import { CloseButton } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { subjectNoticePopupState } from 'recoil/dashboard/subjectNoticePopupState';

export const SubjectNoticePopup = () => {
  const [subjectPopupState, setSubjectPopupState] = useRecoilState(subjectNoticePopupState);
  const { subjectNoticeDetailData, subjectNoticeDetailIsLoading } = useGetSubjectNoticeDetail(subjectPopupState.data);
  const closePopup = () => {
    setSubjectPopupState({ ...subjectPopupState, visible: false });
  };

  return (
    <>
      <div onClick={closePopup} className='backdrop w-100 h-100 position-fixed top-0 start-0 w-100 h-100 z-5000'></div>
      <div className='bg-white position-fixed position-center p-4 rounded-2 popupContainer z-5100'>
        <div className='d-flex align-items-center justify-content-between mb-2'>
          <h3 className='h2 text-gray1 fw-bold py-2 mb-0'>학습 공지사항</h3>
          <CloseButton onClick={closePopup} style={{ fontSize: '24px' }} />
        </div>
        <div className='row body bg-gray10 p-3 border border-start-0 border-end-0' style={{ borderTop: '2px' }}>
          <p className='col'>
            <span className='pe-2'>과목</span>
            <span className='fw-bold text-gray1'>{subjectNoticeDetailData?.data?.course_name}</span>
          </p>
          <p className='col'>
            <span className='pe-2'>작성자</span>
            <span>{subjectNoticeDetailData?.data.created_by}</span>
          </p>
          <p className='col'>
            <span className='pe-2'>등록일</span>
            <span>{moment(subjectNoticeDetailData?.data.created_at).format('YYYY-MM-DD')}</span>
          </p>
        </div>
        <div>
          <div
            className='p-20 overflow-scroll min-h-80'
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: subjectNoticeDetailData?.data.content }}
          ></div>
        </div>
        {/* <div className='border d-flex align-items-center justify-content-between mx-2 rounded-1 p-2 mb-5 cursor-pointer'>
          <span className='text-blue1'>image1.png</span>
          <i className='bi bi-download'></i>
        </div> */}

        {/* <div className='border border-start-0 border-end-0 body'>
          <div className='d-flex align-items-center border border-start-0 border-end-0 cursor-pointer'>
            <div className='p-3 text-gray1 fw-bold'>이전글</div>
            <div>
              <p>학습공지안내</p>
            </div>
          </div>
          <div className='d-flex align-items-center border border-start-0 border-end-0 cursor-pointer'>
            <div className='p-3 text-gray1 fw-bold'>다음글</div>
            <div>
              <p>[공통]온라인 수업 매뉴얼</p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
