import BoardQuill from 'components/common/board/BoardQuill';
import 'react-quill/dist/quill.snow.css'; // Quill의 스타일시트를 가져옵니다.

export function BoardContent({ value, onChange }) {
  return (
    <>
      {/* 내용 */}
      <div className='d-flex py-2'>
        <label htmlFor=''>내용</label>
        <div className='w-100'>
          <BoardQuill value={value} onChange={onChange} />
        </div>
      </div>
    </>
  );
}
