/**
 * 학생 과목 아이템 카드 컴포넌트입니다.
 *
 * @component
 * @param {Object} props - 컴포넌트에 전달되는 props 객체
 * @param {Object} props.item - 과목 아이템 정보
 * @param {number} props.index - 과목 아이템의 인덱스
 * @param {ReactNode} props.children - 컴포넌트의 자식 요소
 * @returns {JSX.Element} 학생 과목 아이템 카드 컴포넌트
 */
export default function SubjectItemCard({ item, index, children }) {
  return (
    <>
      <div key={item.moduleId} className='rounded-2 overflow-hidden bg-white mb-5'>
        {/* header */}
        <div className='p-20 Blue_primary fw-bold text-white'>
          <span className='me-1'>{index + 1}</span>주차
        </div>
        {/* body */}
        <div className='p-30 pb-2 fz-14'>{children}</div>
      </div>
    </>
  );
}
