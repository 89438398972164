import { boardTitleLength } from 'assets/constant';

export function BoardTitle({ value, onChange }) {
  return (
    <>
      {/* 제목 */}
      <div className='d-flex align-items-center py-2'>
        <label>제목</label>
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className='w-100 fw-bold py-1'
          type='text'
          maxLength={boardTitleLength}
          required
        />
      </div>
    </>
  );
}
