import { useHeader } from 'hooks';
import { CardHeader } from '..';

export function StudySentence() {
  const {
    // 메세지
    messageData,
    messageIsLoading,
    messageIsError,
  } = useHeader();
  return (
    <div className='col'>
      <div className='card card-min info-card sales-card p-20'>
        <div className=''>
          <CardHeader title='메세지함'>미확인</CardHeader>

          <div className='d-flex align-items-center'>
            <div
              className='card-icon rounded-circle d-flex align-items-center justify-content-center'
              style={{ background: '#EEEFF7' }}
            >
              <i className='bi bi-envelope-exclamation-fill' style={{ color: '#4E5BA6' }}></i>
            </div>
            <div className='me-2 w-100 text-end d-flex justify-content-end align-items-center'>
              <h6>{messageData?.length ? messageData?.length : 0}</h6> <h6 className='ms-3'>개</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
