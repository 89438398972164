import { Toast } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { adminUserPopupState } from 'recoil/adminpopup/adminUserSettingPopup';

export function PasswordToast({ children, type }) {
  const [popupState, setPopupState] = useRecoilState(adminUserPopupState);
  let toastColor = {
    toastMainBackground: '',
    toastIconColor: '',
    toastTestColor: '',
    toastText: '',
  };
  switch (popupState.toastType) {
    case 'success':
      toastColor = { toastMainBackground: '#22AD5C', toastIconColor: '#22AD5C', toastTestColor: 'white' };
      break;
    case 'danger':
      toastColor = { toastMainBackground: '#f0846f', toastIconColor: '#d54a2f', toastTestColor: 'black' };
      break;
    default:
      toastColor = { toastMainBackground: '#f0846f', toastIconColor: '#d54a2f', toastTestColor: 'black' };
      return;
  }
  return (
    <>
      <Toast
        className='position-fixed toast'
        onClose={() => setPopupState({ ...popupState, toastState: false, toastType: null })}
        show={popupState.toastState}
        delay={2000}
        position={'top-end'}
        autohide
        style={{ background: toastColor.toastMainBackground }}
      >
        <Toast.Body className='d-flex align-items-center'>
          <div
            className='p-2 d-flex justify-content-center align-items-center rounded-5 me-2'
            style={{ background: toastColor.toastIconColor }}
          >
            <i className='bi bi-check-circle text-white'></i>
          </div>
          <span style={{ color: toastColor.toastTestColor }}>
            {/* {popupState.toastType === 'success' ? '비밀번호 변경이 완료되었습니다' : '비밀번호를 확인해주세요'} */}
            {popupState.toastText}
          </span>
        </Toast.Body>
      </Toast>
    </>
  );
}
