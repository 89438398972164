import { ResponsiveBar } from '@nivo/bar';
import { handleNumberGarde } from '../data';
import { reportAdminChartMargin } from '../reportConstant';

export const ReportAdminChart = ({
  title = '기초학습능력 진단',
  data,
  colors,
  keys,
  average = 0,
  maxValue = 100,
  LeftAxis,
  height = 800,
}) => {
  return (
    <div className='flex-grow-1 p-3' style={{ boxSizing: 'border-box' }}>
      <h3 className='text-center mt-3 mb-4 fw-bold'>{title}</h3>
      <div className={`h-${height} chart_urqwmax_aa-dggdeq1clc overflow-hidden w-full`} style={{ textAlign: 'left' }}>
        <ResponsiveBar
          data={data}
          enableLabel
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          // 데이터 그룹화
          // groupMode='grouped'
          // 차트 정렬 방식
          layout='horizontal'
          // 데이터에 사용될 키
          keys={keys}
          // 인덱싱 하는 데 사용되는 키
          indexBy='title'
          // 차트의 여백
          margin={reportAdminChartMargin}
          // 차트아이템의 패딩
          padding={0.2}
          // 최대값
          maxValue={maxValue}
          // valueScale={{ type: 'linear' }}
          // indexScale={{ type: 'band', round: false }}
          colors={colors}
          borderRadius={5}
          axisBottom={{
            tickValues: LeftAxis,
            tickSize: 4,
            tickPadding: 8,
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          // axisBottom={null}
          enableGridY={false}
          totalsOffset={0}
          barSizePx={'36px'}
          enableGridX
          gridXValues={LeftAxis}
          // 평균값 가로선 값
          markers={[
            {
              axis: 'x',
              legend: `평균 : ${handleNumberGarde(average)} 점`,
              legendPosition: 'top',
              value: average,
              lineStyle: { stroke: '#4266FA', strokeWidth: 2, strokeDasharray: '6, 6' },
            },
          ]}
        />
      </div>
    </div>
  );
};
