/**
 * 주 버튼 컴포넌트입니다.
 *
 * @component
 * @param {Object} props - 주 버튼 컴포넌트의 속성
 * @param {ReactNode} props.children - 버튼 내부에 표시될 내용 (기본값: '버튼')
 * @param {function} props.onClick - 버튼 클릭 시 실행될 함수 (기본값: 빈 함수)
 * @param {boolean} props.disabled - 버튼 비활성화 여부 (기본값: false)
 * @returns {JSX.Element} 주 버튼 컴포넌트
 */
export const PrimaryButton = ({ children = '버튼', onClick = () => {}, disabled = false }) => {
  return (
    <button
      className='d-flex justify-content-center align-items-center font-suit py-2 px-4 rounded text-white bg-primary btn-transition border-none'
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
