import { SpinnerLoading } from 'components/common';
import { CardLayout } from 'components/shared/card/CardLayout';
import { CardSectionTitle } from 'components/shared/card/CardSectionTitle';
import { usePDFDownLoad } from 'hooks/report/usePDFDownLoad';
import { useReport } from 'hooks/report/useReport';
import { useReportQuery } from 'hooks/report/useReportQuery';
import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { ReportLectureItem } from './ReportLectureItem';
import { MyNeckItem } from './common/MyNeckItem';
import { PdfDownButton } from './common/PdfDownButton';
import { ReportFooter } from './common/ReportFooter';
import { ReportHeadTitle } from './common/ReportHeadTitle';
import { ReportSelectLayout } from './common/ReportSelectLayout';
import { studentLiberaChanger, studentReportDataChanger } from './data';
import { reportChartColors, reportChartENLeftAxis, reportChartLeftAxis } from './reportConstant';
import { ReportLiberalItem } from './student/ReportLiberalItem';
import { ReportStudentChart } from './student/ReportStudentChart';
import { ReportStudentScoreItem } from './student/ReportStudentScoreItem';

export const Report = () => {
  const navigate = useNavigate();
  const userRole = useRecoilValue(loginUser);
  const [reportState, setReportState] = useRecoilState(studentReportState);
  const { semesterSelector } = useReport();

  const { targetRef, flexRef, liberalRef, handleClickDownPDF } = usePDFDownLoad();

  const handleGoingBack = () => {
    navigate(-1);
  };

  const onChange = (e) => {
    setReportState(e);
  };

  const {
    학기_선택박스_정보,
    학기_선택박스_로딩,
    학생_학과_정보,
    학생_학과_로딩,
    학생_섹션_차트_일번_정보,
    학생_섹션_차트_일번_로딩,
    학생_섹션_이번_교양시청_정보,
    학생_섹션_이번_교양시청_로딩,
    학생_섹션_차트_조회_정보,
    학생_섹션_차트_조회_로딩,
    학생_답변_조회,
    학생_답변_조회_로딩,
  } = useReportQuery();

  useEffect(() => {
    return () => {
      setReportState(null);
    };
  }, []);

  return (
    <section>
      <div className='row'>
        <div className='col'>
          <div className='position-relative'>
            {userRole?.role !== 'STUDENT' && (
              <div className='card p-3 py-4 d-flex justify-content-between align-items-center flex-row'>
                <h3 className='fz-18 fw-bold'>학생 A</h3>
                <button className='border bg-58595B py-2 px-3 rounded text-white' onClick={handleGoingBack}>
                  목록
                </button>
              </div>
            )}
            {/* 셀렉트 박스 */}
            {userRole?.role === 'STUDENT' && (
              <ReportSelectLayout>{semesterSelector(true, onChange)}</ReportSelectLayout>
            )}

            {학기_선택박스_로딩 ||
              학생_섹션_차트_일번_로딩 ||
              (학생_섹션_이번_교양시청_로딩 && (
                <div className='card p-3 w-100' style={{ height: '100px' }}>
                  <SpinnerLoading />
                </div>
              ))}
            {reportState && !학기_선택박스_로딩 && !학생_섹션_차트_일번_로딩 && !학생_섹션_이번_교양시청_로딩 ? (
              <>
                <PdfDownButton
                  onClick={() =>
                    handleClickDownPDF('bg-primary rounded-3 text-white border-none py-3 px-4 btn-transition')
                  }
                />
                <div ref={targetRef}>
                  {/* 타이틀 섹션 */}
                  <ReportHeadTitle
                    semesterData={reportState?.label}
                    name={학생_학과_정보?.data?.student_name}
                    no={학생_학과_정보?.data?.student_number}
                    department={학생_학과_정보?.data?.sub_division}
                  />

                  <CardLayout title='1. 세부 학습목표 성취율'>
                    {/* 프로그레스바 섹션 */}
                    <section>
                      <CardSectionTitle index='1' title='성공적인 대학생활' />
                      <table className='w-full'>
                        <thead>
                          <tr className='fz-14'>
                            <th className='text-start ps-0' colSpan={2}>
                              세부학습목표
                            </th>
                            <th className='text-start'>성취율</th>
                            <th className='text-center'>상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ReportLectureItem
                            title='교육목표 이해도'
                            percent={학생_섹션_차트_일번_정보?.data?.first_one}
                            content='학생은 경복대학교의 설립이념과 교육목표 이해한다.'
                            type={학생_섹션_차트_일번_정보?.data?.first_one_status}
                          />
                          <ReportLectureItem
                            title='대학 자원 활용'
                            percent={학생_섹션_차트_일번_정보?.data?.first_two}
                            content='학생은 성공적인 대학생활을 위하여 대학의 자원을 적절하게 활용한다.'
                            type={학생_섹션_차트_일번_정보?.data?.first_two_status}
                          />
                          <ReportLectureItem
                            title='진단검사 실시'
                            percent={학생_섹션_차트_일번_정보?.data?.first_three}
                            content='학생은 개인 맞춤형 성공지원을 위한 진단검사에 참여한다.'
                            type={학생_섹션_차트_일번_정보?.data?.first_three_status}
                          />
                          <ReportLectureItem
                            title='협력적인 관계형성'
                            percent={학생_섹션_차트_일번_정보?.data?.first_four}
                            content='학생은 지도교수 및 대학 구성원과 협력적인 관계를 형성한다.'
                            type={학생_섹션_차트_일번_정보?.data?.first_four_status}
                          />
                          <ReportLectureItem
                            title='행사참여'
                            percent={학생_섹션_차트_일번_정보?.data?.first_five}
                            content='학생은 진로설계를 위한 추천하는 활동 및 행사에 참여 한다.'
                            type={학생_섹션_차트_일번_정보?.data?.first_five_status}
                          />
                          <ReportLectureItem
                            title='트랙설계'
                            percent={학생_섹션_차트_일번_정보?.data?.first_six}
                            content='학생은 진로설계에 따른 자신의 교육 트랙을 설계 한다.'
                            type={학생_섹션_차트_일번_정보?.data?.first_six_status}
                          />
                        </tbody>
                      </table>
                    </section>

                    <section>
                      <CardSectionTitle index='2' title='미래역량 향상' />
                      <table className='w-full'>
                        <thead>
                          <tr className='fz-14'>
                            <th className='text-start ps-0' colSpan={2}>
                              세부학습목표
                            </th>
                            <th className='text-start'>성취율</th>
                            <th className='text-center'>상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ReportLectureItem
                            title='교육목표 이해도'
                            percent={학생_섹션_차트_일번_정보?.data?.second_one}
                            content='학생은 사회인으로서 요구되는 기초소양과 의사소통능력을 함양한다.'
                            type={학생_섹션_차트_일번_정보?.data?.second_one_status}
                          />
                          <ReportLectureItem
                            title='대학 자원 활용'
                            percent={학생_섹션_차트_일번_정보?.data?.second_two}
                            content='학생은 사회인으로서 필요한 필수교육을 이수한다.'
                            type={학생_섹션_차트_일번_정보?.data?.second_two_status}
                          />
                          <ReportLectureItem
                            title='진단검사 실시'
                            percent={학생_섹션_차트_일번_정보?.data?.second_three}
                            content='학생은 미래사회 기반기술인 AI를 이해하고 활용 할 수 있는 능력을 갖춘다.'
                            type={학생_섹션_차트_일번_정보?.data?.second_three_status}
                          />
                        </tbody>
                      </table>
                    </section>
                  </CardLayout>

                  <CardLayout title='2. 과제수행도'>
                    {/* 차트 섹션 */}
                    <section>
                      <CardSectionTitle index='1' title='기초학습능력진단 검사' />
                      <div ref={flexRef} className='d-flex flex-sm-row flex-column overflow-auto px-5'>
                        {학생_섹션_차트_조회_정보?.data?.map((item) => (
                          <div className='w-full d-flex justify-content-center align-items-center'>
                            <ReportStudentChart
                              maxValue={item.statics_subject_name.includes('영어') ? 60 : 100}
                              LeftAxis={
                                item.statics_subject_name.includes('영어') ? reportChartENLeftAxis : reportChartLeftAxis
                              }
                              chartTitle={
                                item.statics_subject_name.includes('영어')
                                  ? `${item.statics_subject_name} - 60점 만점`
                                  : item.statics_subject_name
                              }
                              data={studentReportDataChanger(
                                item.individual_score,
                                item.subdivision_score,
                                item.grade_score
                              )}
                              colors={reportChartColors}
                            />
                          </div>
                        ))}
                      </div>
                    </section>

                    {/* 테이블 */}
                    <div className='mt-5'>
                      <Table className='w-full'>
                        <thead>
                          <tr className='fz-14'>
                            <th className='text-start align-center' style={{ width: '17.53%' }}>
                              <h4 className='py-3'>구분</h4>
                            </th>
                            <th className='white-nowrap'>
                              <h4 className='py-3'>참여 혹은 이수</h4>
                            </th>
                            <th className='bg-report-myScore-pink' style={{ width: '21.89%' }}>
                              <h4 className='py-3'>
                                나의 점수 <span className='color-primary vertical-2px'>★</span>
                              </h4>
                            </th>
                            <th style={{ width: '21.89%' }}>
                              <h4 className='py-3'>학과 평균 점수</h4>
                            </th>
                            <th style={{ width: '21.89%' }}>
                              <h4 className='py-3'>1학년 평균 점수</h4>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {학생_섹션_차트_조회_정보?.data?.map((item) => (
                            <ReportStudentScoreItem
                              title={item.statics_subject_name}
                              state={item.individual_score > 0 ? 'PASS' : 'FAIL'}
                              content={item.individual_score > 0 ? 'Y' : 'N'}
                              my_score={item.individual_score}
                              average_score={item.subdivision_score}
                              department_average_score={item.grade_score}
                            />
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    {/* 교양 퍼센트 */}
                    <section>
                      <CardSectionTitle index='2' title='교양 교육' />
                      <div ref={liberalRef} className='mt-4 d-block justify-content-evenly d-lg-flex gap-3'>
                        <ReportLiberalItem
                          title='성폭력 교육'
                          content={학생_섹션_이번_교양시청_정보?.data?.sexual_violence_prevent}
                          state={학생_섹션_이번_교양시청_정보?.data?.sexual_violence_prevent}
                        >
                          {학생_섹션_이번_교양시청_정보?.data?.sexual_violence_prevent === 'Y'
                            ? '참여 / 이수'
                            : '미참여 / 미이수'}
                        </ReportLiberalItem>
                        <ReportLiberalItem
                          title='중독예방 교육'
                          content={학생_섹션_이번_교양시청_정보?.data?.addiction_prevent}
                          state={학생_섹션_이번_교양시청_정보?.data?.addiction_prevent}
                        >
                          {학생_섹션_이번_교양시청_정보?.data?.addiction_prevent === 'Y'
                            ? '참여 / 이수'
                            : '미참여 / 미이수'}
                        </ReportLiberalItem>
                        <ReportLiberalItem
                          title='가정폭력교육'
                          content={학생_섹션_이번_교양시청_정보?.data?.domestic_violence_prevent}
                          state={학생_섹션_이번_교양시청_정보?.data?.domestic_violence_prevent}
                        >
                          {학생_섹션_이번_교양시청_정보?.data?.domestic_violence_prevent === 'Y'
                            ? '참여 / 이수'
                            : '미참여 / 미이수'}
                        </ReportLiberalItem>
                        <ReportLiberalItem
                          title='안전 교육'
                          content={학생_섹션_이번_교양시청_정보?.data?.safety_training}
                          state={학생_섹션_이번_교양시청_정보?.data?.safety_training}
                        >
                          {학생_섹션_이번_교양시청_정보?.data?.safety_training === 'Y'
                            ? '참여 / 이수'
                            : '미참여 / 미이수'}
                        </ReportLiberalItem>
                      </div>
                    </section>
                  </CardLayout>

                  <CardLayout title='3. 나의 목표 확인'>
                    {studentLiberaChanger(학생_답변_조회?.data).map((item) => (
                      <MyNeckItem
                        key={item.id}
                        title={item.title}
                        subtitle={item.subtitle}
                        content={item.content}
                        border={item.border}
                      />
                    ))}
                  </CardLayout>

                  <ReportFooter />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
