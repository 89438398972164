import { ReportLiberalItem } from '../student/ReportLiberalItem';

export const ReportAdminReligion = ({ sexual, addiction, domestic, safety }) => {
  return (
    <>
      <ReportLiberalItem title='성폭력 교육' content={sexual > 60 ? 'Y' : 'N'} state={sexual > 60 ? 'Y' : 'N'}>
        {sexual}%
      </ReportLiberalItem>
      <ReportLiberalItem title='중독예방 교육' content={addiction > 60 ? 'Y' : 'N'} state={addiction > 60 ? 'Y' : 'N'}>
        {addiction}%
      </ReportLiberalItem>
      <ReportLiberalItem title='가정폭력교육' content={domestic > 60 ? 'Y' : 'N'} state={domestic > 60 ? 'Y' : 'N'}>
        {domestic}%
      </ReportLiberalItem>
      <ReportLiberalItem title='안전 교육' content={safety > 60 ? 'Y' : 'N'} state={safety > 60 ? 'Y' : 'N'}>
        {safety}%
      </ReportLiberalItem>
    </>
  );
};
