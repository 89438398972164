import { Form } from 'react-bootstrap';

export function BoardThumbnail({ value, onChange }) {
  return (
    <>
      {/* 썸네일 */}
      <Form value={value} onChange={(e) => onChange(e.target.files[0])} className='d-flex align-items-center py-2'>
        <label htmlFor=''>썸네일 (360*185)</label>
        <input className='border' type='file' name='thumbnail-fileInput' accept='image/jpeg, image/png, image/webp' />
      </Form>
    </>
  );
}
