import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { arrChunk } from 'assets/constant';
import { CardHeader } from 'components/common';
import { PaginationCustom } from 'components/common/pagination';
import AdminSelectBox from 'components/subject/AdminSelectBox';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useSubjectNotice } from 'recoil/dashboard/subjectNotice';
import { subjectNoticePopupState } from 'recoil/dashboard/subjectNoticePopupState';
import { useUserState } from 'recoil/useUserState';
import EmptyComponent from '../EmptyComponent';

export const AdminSubjectNotice = ({ data, cardTitle, isLoading }) => {
  const [optionData, setOptionData] = useState([]);
  const [subOptionData, setSubOptionData] = useState([]);
  const [search, setSearch] = useState('');
  const [noticeList, setNoticeList] = useRecoilState(useSubjectNotice);
  const setSubjectNoticePopup = useSetRecoilState(subjectNoticePopupState);

  const [courseId, setCourseId] = useState({ lectureId: null, subjectId: null, subjectTitle: '' });

  const [currentPage, setCurrentPage] = useState(1);
  const user = useRecoilValue(useUserState);

  const {
    // 관리자 - 전체 학과 리스트 조회
    adminSelectBoxQueryData,
    adminSelectBoxQueryIsLoading,
    adminSelectBoxQueryIsError,

    // 관리자 - 해당 학과의 과목 리스트
    adminSubSelectBoxQueryData,
    adminSubSelectBoxQueryIsLoading,
    adminSubSelectBoxQueryIsError,
  } = useGetRequiredApi(courseId?.lectureId);

  const { data: homeSubjectNoticeData } = useQuery({
    queryKey: ['getNoticeList', courseId, user.sub],
    queryFn: async () => {
      try {
        const { data } = await getDataQuery(`/home/users/me/enrollment/${courseId?.subjectId}/notice/all`);
        return { data };
      } catch (error) {
        console.error('error', error);
      }
    },
    enabled: !!courseId?.subjectId && !!courseId?.lectureId,
    select: (data) => data.data,
  });

  const handleSortIndexBtn = useCallback(
    (type) => {
      if (type === 'up') return setNoticeList([...homeSubjectNoticeData].sort((a, b) => a.id - b.id));
      if (type === 'down') return setNoticeList([...homeSubjectNoticeData].sort((a, b) => b.id - a.id));
    },
    [noticeList, setNoticeList]
  );

  // 학과 이벤트 핸들러
  const handleSelectIdBox = (e) => {
    setCourseId({ ...courseId, lectureId: e.value });
  };

  // 과목 이벤트 핸들러
  const handleSubjectSelectBox = (e) => {
    setCourseId({ ...courseId, subjectId: e.value, subjectTitle: e.label });
  };

  // 데이터 패칭시 전역으로 상태관리
  useEffect(() => {
    if (!homeSubjectNoticeData) return setNoticeList([]);
    setNoticeList(homeSubjectNoticeData);
  }, [homeSubjectNoticeData, setNoticeList]);

  // 클리어 이펙트
  useEffect(() => {
    return () => {
      setCurrentPage(1);
      setSearch('');
    };
  }, []);

  // 학과 셀렉트 박스 데이터
  useEffect(() => {
    return setOptionData(
      adminSelectBoxQueryData?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [adminSelectBoxQueryData]);

  // 과목 셀렉트 박스 데이터
  useEffect(() => {
    return setSubOptionData(
      adminSubSelectBoxQueryData?.map((item) => ({
        value: item.account_course_dto.course_id,
        label: item.account_course_dto.course_name,
      }))
    );
  }, [adminSubSelectBoxQueryData]);

  return (
    <div className='col-12'>
      <div className='card p-20'>
        <div className=''>
          <CardHeader title='과목 공지사항'>{cardTitle}</CardHeader>
          <div className=''>
            <div className='d-flex justify-content-between w-100'>
              <div className='d-flex w-100'>
                <label
                  className='me-3 text-nowrap d-flex justify-content-center align-items-center'
                  htmlFor='subjectNotice'
                >
                  과목
                </label>

                <AdminSelectBox
                  lectureIsLoading={adminSelectBoxQueryIsLoading}
                  lectureOption={optionData}
                  lectureOnChange={handleSelectIdBox}
                  lecturePlaceholder={'학과 선택'}
                  subjectIsLoading={adminSubSelectBoxQueryIsLoading}
                  subjectOption={subOptionData}
                  subjectOnChange={handleSubjectSelectBox}
                  subjectPlaceholder={'과목 선택'}
                  adminSelectState={courseId?.lectureId}
                  adminSubSelectBoxQueryIsLoading={adminSubSelectBoxQueryIsLoading}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center mt-2 mb-4'></div>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='border-bottom border-top --Palette-DarkGray-80 fw-bold'>
                  <th style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h4 className='py-3 min-w-70'>번호</h4>
                    </div>
                  </th>
                  <th style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                    <div>
                      <h4 className='py-3'>과목</h4>
                    </div>
                  </th>
                  <th style={{ textAlign: 'start', width: '30.33%', padding: '4px' }}>
                    <div>
                      <h4 className='py-3'>제목</h4>
                    </div>
                  </th>
                  <th style={{ textAlign: 'start', width: '25%', padding: '4px' }}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h4 className='py-3'>작성일</h4>
                      <div className='d-flex flex-column'>
                        <button
                          onClick={() => handleSortIndexBtn('up')}
                          className='btn btn-style-none btn-style-hover p-0'
                        >
                          <i className='bi bi-caret-up-fill --Palette-Gray-50'></i>
                        </button>
                        <button
                          onClick={() => handleSortIndexBtn('down')}
                          className='btn btn-style-none btn-style-hover p-0'
                        >
                          <i className='bi bi-caret-down-fill --Palette-Gray-50'></i>
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!homeSubjectNoticeData && (
                  <td colSpan={4} className='py-5 fw-bold' style={{ textAlign: 'center' }}>
                    <EmptyComponent text={'공지사항을 선택해주세요'} transform />
                  </td>
                )}
                {homeSubjectNoticeData && homeSubjectNoticeData?.length < 1 ? (
                  <tr>
                    <td colSpan={4} className='py-5 fw-bold' style={{ textAlign: 'center' }}>
                      등록된 과목 공지사항이 없습니다.
                    </td>
                  </tr>
                ) : (
                  arrChunk(noticeList)[currentPage - 1]?.map((item, index) => {
                    const date = item.created_at?.slice(0, 10);
                    return (
                      <tr
                        className='border-bottom'
                        key={item.id}
                        onClick={() => {
                          setSubjectNoticePopup({ visible: true, data: item });
                          // handleGoingNotice(item.url);
                        }}
                      >
                        <th style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                          <div className='p-1'>
                            {currentPage === 1 && index + 1}
                            {currentPage > 1 && (currentPage - 1) * 5 + (index + 1)}
                          </div>
                        </th>
                        <td style={{ textAlign: 'start', width: '16.66%', padding: '4px' }}>
                          {courseId.subjectTitle ? (
                            <div className='py-3'>{courseId.subjectTitle}</div>
                          ) : (
                            <div className='py-3'>{item.course_name}</div>
                          )}
                        </td>
                        <td style={{ textAlign: 'start', width: '30.33%', padding: '4px' }}>
                          <div className='py-3'>{item.title}</div>
                        </td>

                        <td style={{ textAlign: 'start', width: '25%', padding: '4px' }}>
                          <div className='py-3'>{date}</div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {/* 페이지네이션 */}

            {homeSubjectNoticeData && (
              <div className='d-flex align-items-center justify-content-center position-relative'>
                <p className='d-none d-md-block float-left position-absolute top-left-50 fz-12'>
                  전체 게시물수 : {homeSubjectNoticeData?.length} 전체 페이지 :{arrChunk(homeSubjectNoticeData)?.length}
                  {/* &nbsp; / {Math.ceil(noticeList.length / 5)} */}
                </p>
                <PaginationCustom
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  data={homeSubjectNoticeData}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
