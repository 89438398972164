import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

function useGetCommonQuery() {
  const token = getSessionStorage();

  const decoded = jwtDecode(token);

  const commonApis = useQueries({
    queries: [
      // 공통 - [활동] 전체 활동 스트림
      {
        queryKey: ['StudentActivityStream', decoded.sub],
        queryFn: () => getDataQuery('/activity/users/me'),
      },
    ],
  });

  const [commonLectureNotice] = commonApis.map((query) => query);
  const [commonLectureNoticeData] = commonApis.map((query) => query?.data?.data);

  const { isLoading: commonLectureNoticeIsLoading, isError: commonLectureNoticeIsError } = commonLectureNotice;

  return {
    // 공통 - [활동] 전체 활동 스트림
    commonLectureNoticeData,
    commonLectureNoticeIsLoading,
    commonLectureNoticeIsError,
  };
}

export default useGetCommonQuery;
