export const ReportSelectLayout = ({ children }) => {
  return (
    <div className='card p-20 px-4'>
      {/* 헤더 */}
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='d-flex align-items-center white-nowrap fw-bold'>
          <i className='bi bi-bank2 me-2 color-primary'></i>
        </h3>

        <div className='d-flex align-items-center w-100'>{children}</div>
      </div>
    </div>
  );
};
