// export const cookieDomainCha = `.cha.ac.kr`;
export const cookieDomainKbu = `.lineedu.net`;
// export const cookieDomainDdu = `.ddu.ac.kr`;

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const date = today.getDate();
const day =
  today.getDay() === 0
    ? '일요일'
    : today.getDay() === 1
      ? '월요일'
      : today.getDay() === 2
        ? '화요일'
        : today.getDay() === 3
          ? '수요일'
          : today.getDay() === 4
            ? '목요일'
            : today.getDay() === 5
              ? '금요일'
              : today.getDay() === 6
                ? '토요일'
                : '';

const SUBJECTS_LIST = [
  {
    title: '전기이론',
    code: '123456',
    teacher: '교수 A',
    assistant: '조교 A',
    department: '전자공학',
  },
  {
    title: '시퀀스제어 실습',
    code: '123456',
    teacher: '교수 A',
    assistant: '조교 A',
    department: '전자공학',
  },
  {
    title: '빅데이터 실습',
    code: '123456',
    teacher: '교수 A',
    assistant: '조교 A',
    department: '전자공학',
  },
  {
    title: '항공전자정비',
    code: '123456',
    teacher: '교수 A',
    assistant: '조교 A',
    department: '전자공학',
  },
  {
    title: '웹 기획 기초 I',
    code: '123456',
    teacher: '교수 A',
    assistant: '조교 A',
    department: '전자공학',
  },
  {
    title: '데이터베이스 실습',
    code: '123456',
    teacher: '교수 A',
    assistant: '조교 A',
    department: '전자공학',
  },
];

/**
 *
 * @param {Array} data 청크로 나눌 배열
 * @param {number} size 나누는 배열의 크기 (기본값:5)
 * @returns
 */
const arrChunk = (data = [], size = 5) => {
  const arr = [];
  for (let i = 0; i < data.length; i += size) {
    arr.push(data.slice(i, i + size));
  }
  return arr;
};

const activeDateSliceFn = (string) => {
  if (!string) return '기본학기';
  const dateArr = string.split('-');
  const manufacture = [dateArr[0], dateArr[1], dateArr[2].split('T')[0], dateArr[2].split('T')[1].slice(0, 5)];
  return manufacture;
};

// const calendarDateCalculate = (string)=>{
//   const date = new Date(string)
//   date.getFullYear
// }

/**
 *
 * @param {string} string 영문의 카테고리를 한글로 바꿔주는 함수입니다.
 * @returns
 */
const categoryType = (string) => {
  let type = '';

  switch (string) {
    case 'Assignment':
      type = '과제';
      break;
    case 'Quiz':
      type = '평가';
      break;
    case 'Discussion':
      type = '토론';
      break;
    case 'ExternalTool':
      type = '온라인';
      break;
    case 'zoom':
      type = '온라인';
      break;
    case '동영상':
      type = '동영상';
      break;
    case 'File':
      type = '파일';
      break;
    case 'ExternalUrl':
      type = '링크';
      break;
    default:
      type = '강의';
      break;
  }
  return type;
};

export const checkCategory = (type) => {
  let category = false;
  switch (type) {
    case 'zoom':
      category = false;
      break;
    case 'File':
      category = false;
      break;
    case 'ExternalUrl':
      category = false;
      break;

    default:
      category = false;
      break;
  }
};

const calendarColor = [
  '#F7B895',
  '#FFE093',
  '#A8D18E',
  '#92ACA3',
  '#93CDCD',
  '#85D2F5',
  '#A6ADD2',
  '#D5B8F7',
  '#B2B7C2',
];
const streamColors = [
  '#F0712C',
  '#FFC228',
  '#52A31D',
  '#255A48',
  '#279C9C',
  '#0BA5EC',
  '#4E5BA6',
  '#AC71F0',
  '#667085',
];

const randomColors = () => {
  const randomIndex = Math.floor(Math.random() * streamColors.length);
  return streamColors[randomIndex];
};

const roundHundredNumber = (number) => {
  try {
    return Math.round(number * 100);
  } catch (error) {
    return 0;
  }
};

export const decimalPointRemovalFn = (str = 0) => {
  let num = 0;
  try {
    num = parseInt(str, 10);
    return num;
  } catch (error) {
    return (num = 0);
  }
};

const cookieOption = {
  path: '/',
  secure: true,
  sameSite: 'none',
  expires: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000), //3일 후
};

const findUserRoleImg = (role) => {
  if (role === 'STUDENT') return '/assets/img/icon/student_profile2.svg';
  if (role === 'TEACHER') return '/assets/img/icon/teacher_profile2.svg';
  if (role === 'ADMIN') return '/assets/img/icon/admin_profile2.svg';
};

/**
 *
 * @param {string} token 세션에 저장할 토큰값
 * @param {string} name 세션의 이름 ** 기본값 accessToken
 * @returns
 */
export const setSessionStorage = (token, name = 'accessToken') => {
  sessionStorage.setItem(name, token);
  return;
};

/**
 *
 * @param {string} name 지울 세션의 이름 ** 기본값 accessToken
 * @returns
 */
export const removeSessionStorage = (name = 'accessToken') => {
  sessionStorage.removeItem(name);
  return;
};

/**
 *
 * @param {string} name 가져올 세션의 이름값 ** 기본값 accessToken
 * @returns
 */
export const getSessionStorage = (name = 'accessToken') => {
  try {
    const getSession = sessionStorage.getItem(name);
    return getSession;
  } catch (error) {
    console.error('error', error);
  }
};

export const setLocalStorage = (name, data, type = 'string') => {
  try {
    if (type === 'string') {
      localStorage.setItem(name, data);
      return;
    }
    if (type === 'object') {
      localStorage.setItem(name, JSON.stringify(data));
      return;
    }
    return;
  } catch (error) {
    console.error('error', error);
  }
};

export const getLocalStorage = (name, type = 'string') => {
  try {
    const localData = localStorage.getItem(name);
    if (type === 'string') {
      return localData;
    }
    if (type === 'object') {
      return JSON.parse(localData);
    }
  } catch (error) {
    console.error('error', error);
  }
};

export const removeLocalStorage = (name) => {
  try {
    localStorage.removeItem(name);
  } catch (error) {
    console.error('error', error);
  }
};

export const quillModules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: ['Roboto', 'Raleway', 'Montserrat', 'Lato', 'Rubik', 'serif'] }], // 폰트 목록 추가
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, 'link'],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color',
          ],
        },
        { background: [] },
      ],
      ['image', 'video'],
      ['clean'],
    ],
  },
};

export const boardTitleLength = 50;
export const boardContentLength = 1000;

export const pdfStudentArrList = [
  { name: '[경복대] 학습자 가이드', path: '[경복대] 학습자 가이드_0302.pdf', createAt: '2024.03.03' },
];
export const pdfTeacherArrList = [
  { name: '[경복대] 교수자 가이드', path: '[경복대] 교수자 가이드_0302.pdf', createAt: '2024.03.03' },
];
export const pdfAdminArrList = [
  { name: '[경복대] 관리자 가이드', path: '[경복대] 관리자 가이드_0302.pdf', createAt: '2024.03.03' },
];

export const debounceSecond = 600;

export const passwordMinLength = 8;
export const passwordMaxLength = 20;

export const week15Item = [...Array(15).keys()].map((i) => i + 1);

export const zoomSettingArr = [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50];

export const NOTICE = 'NOTICE';
export const FAQ = 'FAQ';
export const REFERENCE = 'REFERENCE';

const ATTENDANCETHLENGTH = 16;
export const ATTENDANCETABLETH = Array.from({ length: ATTENDANCETHLENGTH }, (_, i) => i + 1);

export const TOOLTIPMESSAGE = 'ZOOM 출결 상태는 ZOOM 출석현황에서 확인';

/**
 * 해당 변수는 잔디의 상태를 나타내는 변수입니다. 완료 상태
 */
export const JANDI_COMPLETED = 'completed';
/**
 * 해당 변수는 잔디의 상태를 나타내는 변수입니다. 미완료 상태
 */
export const JANDI_UNLOCKED = 'unlocked';
/**
 * 해당 변수는 잔디의 상태를 나타내는 변수입니다. 진행중 상태
 */
export const JANDI_STARTED = 'started';
/**
 * 해당 변수는 잔디의 상태를 나타내는 변수입니다. 진행중 상태
 */
export const JANDI_LOCKED = 'locked';
/**
 * 해당 변수는 잔디의 갯수를 나타내는 변수입니다. 16주차
 */
export const JANDI_ARR_LENGTH = 15;

export {
  SUBJECTS_LIST,
  activeDateSliceFn,
  arrChunk,
  calendarColor,
  categoryType,
  cookieOption,
  date,
  day,
  findUserRoleImg,
  month,
  randomColors,
  roundHundredNumber,
  streamColors,
  year,
};
