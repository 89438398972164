export default function StudentProgressEmpty() {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center p-5'>
      <svg width='104' height='114' viewBox='0 0 104 114' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1575_89702)'>
          <path
            d='M97.7503 11.7147C94.2752 11.7147 91.4473 9.07271 91.4473 5.82612C91.4473 2.57953 94.2752 -0.0625 97.7503 -0.0625C101.225 -0.0625 104.053 2.57953 104.053 5.82612C104.053 9.07271 101.225 11.7147 97.7503 11.7147ZM97.7503 3.86325C96.5905 3.86325 95.6493 4.74261 95.6493 5.82612C95.6493 6.90963 96.5905 7.78899 97.7503 7.78899C98.9101 7.78899 99.8513 6.90963 99.8513 5.82612C99.8513 4.74261 98.9101 3.86325 97.7503 3.86325Z'
            fill='#E0E2E7'
          />
          <path d='M43.8027 111.258L49.7453 105.706L52.7166 108.482L46.774 114.034L43.8027 111.258Z' fill='#E0E2E7' />
          <path d='M60.8867 107.789L63.858 105.013L69.8005 110.565L66.8293 113.341L60.8867 107.789Z' fill='#E0E2E7' />
          <path
            d='M6.40264 24.4715H0.0996094V20.5458C0.0996094 14.5904 5.2807 9.75 11.6552 9.75H15.8572V15.6386H11.6552C8.75997 15.6386 6.40264 17.841 6.40264 20.5458V24.4715Z'
            fill='#E0E2E7'
          />
          <path
            d='M84.1384 24.4715H77.8354V20.5458C77.8354 17.841 75.4781 15.6386 72.5829 15.6386H68.3809V9.75H72.5829C78.9573 9.75 84.1384 14.5904 84.1384 20.5458V24.4715Z'
            fill='#E0E2E7'
          />
          <path d='M32.6641 9.75H51.5732V15.6386H32.6641V9.75Z' fill='#E0E2E7' />
          <path d='M32.6641 82.3828H43.1691V88.2714H32.6641V82.3828Z' fill='#E0E2E7' />
          <path
            d='M15.8572 88.2684H11.6552C5.2807 88.2684 0.0996094 83.428 0.0996094 77.4726V73.5469H6.40264V77.4726C6.40264 80.1775 8.75997 82.3798 11.6552 82.3798H15.8572V88.2684Z'
            fill='#E0E2E7'
          />
          <path d='M0.0996094 40.1797H6.40264V57.8455H0.0996094V40.1797Z' fill='#E0E2E7' />
          <path d='M77.8359 40.1797H84.139V49.9941H77.8359V40.1797Z' fill='#E0E2E7' />
          <path
            d='M57.4641 103.966C55.3714 103.966 53.6738 102.376 53.6738 100.425V46.6582C53.6738 44.7032 55.3756 43.1172 57.4641 43.1172C58.4767 43.1172 59.4264 43.4862 60.1449 44.1536L99.7826 81.1891C100.493 81.8486 100.9 82.7594 100.9 83.6976C100.9 85.6526 99.1985 87.2387 97.1101 87.2387H76.3437L60.1954 102.883C59.46 103.578 58.4767 103.966 57.4641 103.966ZM59.9769 52.3231V94.6073L72.7342 82.249C73.3309 81.6759 74.1461 81.35 75.0033 81.35H91.0466L59.9769 52.3231Z'
            fill='#E0E2E7'
          />
        </g>
        <defs>
          <clipPath id='clip0_1575_89702'>
            <rect width='104' height='114' fill='white' />
          </clipPath>
        </defs>
      </svg>

      <p className='--Palette-Gray-70 fw-bold font-suit fz-15 letter-6 m-0 mt-3'>강의 과목을 선택해주세요.</p>
    </div>
  );
}
