import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export function useSupporting(path, type) {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const supportingApi = useQueries({
    queries: [
      //  지원센터 공지&뉴스 불러오는 api
      {
        queryKey: ['notice&news', decoded],
        queryFn: () => getDataQuery('/help/notification/all'),
      },
      // 지원센터 공지&뉴스 디테일 api
      {
        queryKey: ['notice&newsDetail', decoded, path],
        queryFn: () => getDataQuery(`/help/notification/${path}`),
        enabled: !!path && type === 'NOTICE',
      },

      // faq 불러오는 api
      {
        queryKey: ['FAQ', decoded],
        queryFn: () => getDataQuery(`/help/faq/all`),
      },

      // faq 디테일 불러오는 api
      {
        queryKey: ['FAQDetail', decoded, path],
        queryFn: () => getDataQuery(`/help/faq/${path}`),
        enabled: !!path && type === 'FAQ',
      },

      // 자료실 불러오는 api
      {
        queryKey: ['reference', decoded],
        queryFn: () => getDataQuery(`/help/data-room/all`),
      },

      // 자료실 디테일 불러오는 api
      {
        queryKey: ['referenceDetail', decoded, path],
        queryFn: () => getDataQuery(`/help/data-room/${path}`),
        enabled: !!path && type === 'REFERENCE',
      },
    ],
  });
  const [supportNoticeNews, supportNoticeNewsDetail, faq, faqDetail, reference, referenceDetail] = supportingApi.map(
    (query) => query
  );
  //  지원센터 공지&뉴스 불러오는 api
  const [
    supportNoticeNewsData,
    supportNoticeNewsDetailData,
    faqData,
    faqDetailData,
    referenceData,
    referenceDetailData,
  ] = supportingApi.map((query) => query?.data?.data);

  const { isLoading: supportNoticeNewsIsLoading, isError: supportNoticeNewsIsError } = supportNoticeNews;
  const { isLoading: supportNoticeNewsDetailIsLoading, isError: supportNoticeNewsDetailIsError } =
    supportNoticeNewsDetail;
  const { isLoading: faqIsLoading, isError: faqIsError } = faq;
  const { isLoading: faqDetailIsLoading, isError: faqDetailIsError } = faqDetail;

  const { isLoading: referenceIsLoading, isError: referenceIsError } = reference;
  const { isLoading: referenceDetailIsLoading, isError: referenceDetailIsError } = referenceDetail;

  return {
    //  지원센터 공지&뉴스 불러오는 api
    supportNoticeNewsData,
    supportNoticeNewsIsLoading,
    supportNoticeNewsIsError,

    // 지원센터 공지&뉴스 디테일 api
    supportNoticeNewsDetailData,
    supportNoticeNewsDetailIsLoading,
    supportNoticeNewsDetailIsError,

    // faq 전체 데이터 불러오기
    faqData,
    faqIsLoading,
    faqIsError,

    // faq 디테일 불러오기
    faqDetailData,
    faqDetailIsLoading,
    faqDetailIsError,

    // 자료실 데이터 불러오기
    referenceData,
    referenceIsLoading,
    referenceIsError,

    // 자료실 디테일 데이터 불러오기
    referenceDetailData,
    referenceDetailIsLoading,
    referenceDetailIsError,
  };
}
