export const ReportStudentTableItem = ({ index, classNumber, name, department, ...rest }) => {
  return (
    <>
      <tr className='border-bottom fz-14'>
        <td className='text-center py-3 align-middle fz-14'>{index}</td>
        <td className='py-3 align-middle'>{classNumber}</td>
        <td className='py-3 align-middle' style={{ width: '30%' }}>
          {name}
        </td>
        <td className='py-3 align-middle' style={{ width: '30%' }}>
          {department}
        </td>
        <td className='py-3 align-middle'>
          <button {...rest} className='badge link-badge border fz-14 bg-second'>
            자세히 보기
          </button>
        </td>
      </tr>
    </>
  );
};
