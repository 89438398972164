import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

/**
 *
 * @param {number} course_id 과목의 아이디
 * @param {number} id 과목 안 공지의 아이디
 */
export function useGetSubjectNoticeDetail({ course_id, id }) {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const {
    data: subjectNoticeDetailData,
    isLoading: subjectNoticeDetailIsLoading,
    isError: subjectNoticeDetailIsError,
  } = useQuery({
    queryKey: ['subjectDetail', decoded, id, course_id],
    queryFn: () => getDataQuery(`/home/courses/${course_id}/notice/${id}`),
  });

  return {
    subjectNoticeDetailData,
    subjectNoticeDetailIsLoading,
    subjectNoticeDetailIsError,
  };
}
