import { calendarColor } from 'assets/constant';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCalendarIndex } from 'recoil/caleandar/calendarIndex';
import { useCalendarMonthData } from 'recoil/caleandar/calendarMonthData';
import { momentFormatter } from 'util/common/momentUtill';
import './calendar.css';

export const ScheduleCalendar = ({ data }) => {
  const setCalendarMonth = useSetRecoilState(useCalendarMonthData);
  const [value, onChange] = useState(new Date());
  const [calendarLabel, setCalendarLabel] = useState(new Date());
  const calendarIndex = useRecoilValue(useCalendarIndex);
  //
  const startDay = moment(new Date()).clone().startOf('month').format('YYYY-MM-DD');
  const endDay = moment(new Date()).clone().endOf('month').format('YYYY-MM-DD');
  //
  const [monthStart_end, setMonthStart_end] = useState({
    start_at: `${startDay}T00:00:00.000Z`,
    end_at: `${endDay}T23:59:59.999Z`,
  });
  let countNumber = 0;

  const tileContent = ({ date, view }) => {
    if (countNumber > 8) countNumber = 0;
    const localDate = new Date(date.toISOString());

    const dateString = localDate.toISOString().split('T')[0]; //2023-12-31
    const dateAdd = moment(dateString).add(1, 'days'); // "2024-01-01T00:00:00.000Z"
    const calendarDay = dateAdd.format('YYYY-MM-DD'); // '2024-01-01'

    // 이벤트가 없는 경우 null 반환

    if (view === 'month') {
      const ScheduleData = filterCurrentCalendarMonthData?.filter(
        (el) => momentFormatter(el.start_at) === momentFormatter(calendarDay)
      );

      return (
        <div className='position-relative'>
          <ul className='position-absolute d-flex justify-content-around mt-1 w-100'>
            {ScheduleData.map((item) => (
              <li className='calendar-filterItem' key={item.id} style={{ background: calendarColor[countNumber] }}>
                <div hidden className='hidden'>
                  {++countNumber}
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const filterCurrentCalendarMonthData = data?.filter((el) => {
    const start_Date = moment(el.start_at).format('YYYY-MM');
    const end_Date = moment(el.start_at).format('YYYY-MM');
    const calendarLabelMoment = moment(calendarLabel).format('YYYY-MM');
    const calendarEndMoment = moment(calendarLabel).format('YYYY-MM');
    if (start_Date === calendarLabelMoment) return el;
    if (end_Date === calendarEndMoment) return el;
  });

  useEffect(() => {
    const startDay = moment(calendarLabel).clone().startOf('month').format('YYYY-MM-DD');
    const endDay = moment(calendarLabel).clone().endOf('month').format('YYYY-MM-DD');
    setCalendarMonth({
      start_at: `${startDay}T00:00:00.000Z`,
      end_at: `${endDay}T23:59:59.999Z`,
    });
  }, [setMonthStart_end, calendarLabel]);

  return (
    <>
      <div className='calendar-container'>
        <Calendar
          next2Label={null}
          prev2Label={null}
          // 보여질 날짜
          // activeStartDate={value}

          // 네이게이션 라벨 (March 2024)
          // navigationLabel={({ date, label, locale, view }) => {
          // return `date: ${date.toLocaleDateString(locale)}`;
          // }}
          onActiveStartDateChange={
            // action => 'next', 'prev' 버튼을 눌렀을때 'next' 혹은 'prev'를 반환함
            // activeStartDate => 이동되었을때 날자를 반환함
            // value = 선택되어있던 기본값, 처음 들어올때 현재날짜의 값
            // view => 기본값 month, 년도 => year
            ({ action, activeStartDate, value, view }) => {
              if (action === 'prev') return setCalendarLabel(activeStartDate);
              if (action === 'next') return setCalendarLabel(activeStartDate);
            }
          }
          formatDay={(locale, date) => date.getDate().toString()}
          showNeighboringMonth={false}
          tileContent={tileContent}
          onChange={onChange}
          value={value}
          locale='ko'
          // formatShortWeekday={(locale, date) => {

          //   return ['Sun', 'M', 'T', 'W', 'T', 'F', 'Sat'][date.getDay()];
          // }}
        />
      </div>

      <div className='pt-md-4 pt-5  border-top'>
        <h3 className='fz-18 fw-bold mb-3'>다가오는 일정</h3>

        <ol>
          {filterCurrentCalendarMonthData?.map((item, index) => (
            <li key={item.id} className='d-flex align-items-center mt-3'>
              <ul className='d-flex justify-content-center align-items-center'>
                {item.start_at === item.end_at ? (
                  <li
                    className='calendar_day--circle d-flex justify-content-center align-items-center me-3 --Palette-DarkGray-80 me-2'
                    style={{ background: calendarColor[index] }}
                  >
                    {moment(item.start_at).format('DD')}
                  </li>
                ) : (
                  <>
                    <li
                      className='calendar_day--circle d-flex justify-content-center align-items-center me-3 --Palette-DarkGray-80 me-2 position-relative z-50'
                      style={{ background: calendarColor[index] }}
                    >
                      {moment(item.start_at).format('DD')}
                    </li>

                    <li
                      className='calendar_day--circle d-flex justify-content-center align-items-center me-3 --Palette-DarkGray-80 position-relative'
                      style={{ background: calendarColor[index] }}
                    >
                      {moment(item.end_at).format('DD')}
                      <div
                        className='position-absolute top-50-left-0 transform-reversal'
                        style={{ background: calendarColor[index] }}
                      ></div>
                    </li>
                  </>
                )}
              </ul>

              <h4 className='fz-16 --Palette-Gray-70'>{item.title}</h4>
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};
