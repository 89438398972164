/**
 *
 * @param {boolean} done 완료, 미완료 불린값
 * @param {boolean} disable 디스에이블 불린값
 * @returns
 */
export function IsDone({ done = true, disable = false }) {
  return (
    <div
      className="py-1 px-2 text-white rounded-5 tiny"
      style={{ background: done ? "#0D6EFD" : disable ? "#E0E2E7" : "#6C757D" }}
    >
      {done ? "완료" : "미완료"}
    </div>
  );
}
