import { Button, CloseButton, Form, InputGroup } from 'react-bootstrap';

export const GradeSettingPopup = ({ setVisiblePopup, popupType }) => {
  const closePopup = () => {
    setVisiblePopup(false);
  };
  return (
    <>
      <div onClick={closePopup} className='backdrop w-100 h-100 position-fixed top-0 start-0 w-100 h-100'></div>
      <div className='bg-white position-fixed position-center p-4 rounded-2 popupContainer w-50'>
        <div className='d-flex align-items-center justify-content-between border border-start-0 border-end-0 border-top-0'>
          <h3 className='h2 text-gray1 fw-bold py-2 mb-3'>{popupType}</h3>
          <CloseButton onClick={closePopup} style={{ fontSize: '24px' }} />
        </div>

        <div className='border border-top-0 border-start-0 border-end-0 py-3'>
          <div className='row align-items-center py-3'>
            <label className='col-3 fw-bold' htmlFor='weighted'>
              {popupType === '출석' ? '출석 총 가중치 (%)' : '가중치 (%)'}
            </label>
            <InputGroup className='col-auto p-0 align-items-center' style={{ width: '250px' }}>
              <Form.Control placeholder='20.00' aria-label="Recipient's username" aria-describedby='basic-addon2' />
              <InputGroup.Text id='basic-addon2'>%</InputGroup.Text>
            </InputGroup>
          </div>

          <div className='row align-items-center py-3'>
            <label className='col-3 fw-bold' htmlFor='weightedCheck'>
              항목 점수 비공개
            </label>
            <Form.Check className='col-auto p-0 m-0' type='checkbox' id='weightedCheck' style={{ width: '250px' }} />
          </div>
        </div>

        {popupType === '출석' && (
          <div className='border border-top-0 border-start-0 border-end-0 py-3'>
            <div className='row align-items-center py-3'>
              <label className='col-3 fw-bold' htmlFor='weighted'>
                현장 출석 가중치 (%)
              </label>
              <InputGroup className='col-auto p-0 align-items-center' style={{ width: '250px' }}>
                <Form.Control placeholder='5.00' aria-label="Recipient's username" aria-describedby='basic-addon2' />
                <InputGroup.Text id='basic-addon2'>%</InputGroup.Text>
              </InputGroup>
            </div>
            <div className='row align-items-center py-3'>
              <label className='col-3 fw-bold' htmlFor='weighted'>
                온라인 출석 가중치 (%)
              </label>
              <InputGroup className='col-auto p-0 align-items-center' style={{ width: '250px' }}>
                <Form.Control
                  disabled
                  placeholder='5.00'
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                />
                <InputGroup.Text id='basic-addon2'>%</InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        )}

        {/* 버튼 */}
        <div className='d-flex justify-content-center mt-5'>
          <Button className='mx-1' variant='primary'>
            등록
          </Button>
          <Button className='mx-1' variant='secondary'>
            취소
          </Button>
        </div>
      </div>
    </>
  );
};
