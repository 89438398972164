import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { findUserRoleImg } from 'assets/constant';
import BreadCrumb from 'components/shared/BreadCrumb';
import moment from 'moment';
import { useState } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { useUserState } from 'recoil/useUserState';
import { PasswordChange } from '..';
import SettingItem from './SettingItem';

export default function Setting() {
  const loginUser = useRecoilValue(useUserState);
  const [key, setKey] = useState('profile');

  const { data: userInfo } = useQuery({
    queryKey: ['userInfo'],
    queryFn: () => getDataQuery(`/users?id=${loginUser.loginId}`),
    enabled: key === 'profile',
  });
  const { data: userSetting } = useQuery({
    queryKey: ['userSetting'],
    queryFn: () => getDataQuery(`/users/setting?id=${loginUser.loginId}`),
    enabled: key === 'setting',
  });

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/setting'} sublink={'/setting'} title={'User Accounts'} subTitle={'계정관리'} />
        <div className='row'>
          {/* 이미지 카드 */}
          <div className='col-md-4 col-12'>
            <div className='card p-4 text-center'>
              <div>
                <img src={findUserRoleImg(loginUser.role)} alt='유저 프로필사진' />
              </div>
              <div className='gap-3 mt-3'>
                <h3 className='h1'>{userInfo?.data?.name}</h3>
                <p className='mt-2'>학번 /사번 : {userInfo?.data?.userNumber}</p>
                <p className='mt-2'>
                  {userInfo?.data?.divisionName} {userInfo?.data?.subDivisionName}
                </p>
              </div>
            </div>
          </div>

          {/* 탭 */}
          <div className='col-md-8 col-12'>
            <div className='card p-4'>
              <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                defaultActiveKey='home'
                transition={false}
                id='noanim-tab-example'
                className='mb-3'
              >
                <Tab eventKey='profile' title='프로필'>
                  <div>
                    <h4 className='fw-bold h2'>사용자 상세정보</h4>

                    <ul className='py-3'>
                      <SettingItem label='이름' content={userInfo?.data?.name.split('_')[0]} />
                      <SettingItem label='생년월일' content={userInfo?.data?.birth} />
                      <SettingItem label='연락처' content={userInfo?.data?.mobile} />
                      <SettingItem label='이메일' content={userInfo?.data?.email} />
                      <SettingItem label='학번/사번' content={userInfo?.data?.userNumber} />
                      <SettingItem
                        label='학부/학과'
                        content={`${userInfo?.data?.divisionName} / ${userInfo?.data?.subDivisionName}`}
                      />
                    </ul>
                  </div>
                </Tab>
                {/* <Tab className='hidden' eventKey='profileEdit' title='프로필 수정'>
                  <ul>
                    <li className='row py-3'>
                      <p className='col-2'>프로필 사진</p>
                      <div className='col'>
                        <div>asd</div>
                        <div>
                          <button>1</button>
                          <button>2</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Tab> */}
                <Tab eventKey='setting' title='설정'>
                  <ul className='py-3'>
                    <SettingItem
                      label='최근접속일시'
                      content={moment(userSetting?.data?.recentLoginAt).format('YYYY.MM.DD HH:mm:ss')}
                    />

                    <li className='row py-2'>
                      <p className='col-2 min-w-90'>알림 설정</p>
                      <div className='col'>
                        <Form className='d-flex flex-column align-items-start'>
                          <Form.Check disabled reverse type='switch' id='custom-switch1' label='사이트 알림' />
                          <Form.Check
                            disabled
                            reverse
                            className='mt-3'
                            type='switch'
                            id='custom-switch2'
                            label='이메일 알림'
                          />
                        </Form>
                      </div>
                    </li>
                  </ul>
                </Tab>
                <Tab eventKey='password' title='비밀번호 변경'>
                  <PasswordChange userNum={userInfo?.data?.userNumber} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
