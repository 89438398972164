import { debounceSecond } from 'assets/constant';
import { CardHeader } from 'components/common';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useSupporting } from 'hooks';
import { useNoticeState } from 'hooks/supporting/useNoticeState';
import { usePostSupporting } from 'hooks/supporting/usePostSupporting';
import usePutSupporting from 'hooks/supporting/usePutSupporting';
import { debounce } from 'lodash';
import { BoardContent, BoardFixed, BoardPublic, BoardTitle } from 'pages/supporting';
import { BoardEditFile } from 'pages/supporting/BoardEditFile';
import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { BoardTypeFaq } from './BoardTypeFaq';

export const FaqEdit = () => {
  const { faqId } = useParams();
  const navigate = useNavigate();
  const {
    noticeState,
    handleTitleChange,
    handleContentChange,
    handlePostStateChange,
    handlePostTypeChange,
    handleTopFixFlagChange,
    handleFileChange,
    resetNoticeState,
    handleEditFileChange,
  } = useNoticeState();
  const { editReferenceMutation } = usePutSupporting(faqId);
  const { editFileMutate } = usePostSupporting(faqId);

  const {
    // faq 디테일 불러오기
    faqDetailData,
    faqDetailIsLoading,
    faqDetailIsError,
  } = useSupporting(faqId);

  const debounceHandleSubmit = debounce(async () => {
    const { fileList, thumbnail, editFileList, ...boardPostData } = noticeState;
    try {
      const formData = new FormData();
      if (fileList.length > 0)
        fileList.forEach((file) => {
          formData.append('mediaList', file);
        });

      editReferenceMutation.mutate(boardPostData);
      if (fileList.length > 0) editFileMutate.mutate(formData);
      navigate(`/faq/detail/${faqId}`);
    } catch (error) {
      console.error('error', error);
    }
  }, debounceSecond);

  const handleSubmit = (e) => {
    e.preventDefault();
    debounceHandleSubmit();
  };

  useEffect(() => {
    if (!faqDetailData) return;
    const { title, content, postState, postType, topFixFlag, mediaInfoDtoList } = faqDetailData;
    handleTitleChange(title);
    handleContentChange(content);
    handlePostStateChange(postState);
    handlePostTypeChange(postType);
    handleTopFixFlagChange(topFixFlag);
    handleEditFileChange(mediaInfoDtoList);

    return () => {
      resetNoticeState();
    };
  }, [faqDetailData]);

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/faq'} sublink={'/faq'} title={'Help Center'} subTitle={'지원센터'}>
          &nbsp;/ faq / 공지 등록/수정
        </BreadCrumb>
        <div className='card p-20'>
          <CardHeader noRightBorder={false} title='자료실' visibleContent={false}></CardHeader>

          <Form onSubmit={handleSubmit} action='' className='write-form'>
            <BoardTypeFaq />
            <BoardTitle value={noticeState.title} onChange={handleTitleChange} />
            <BoardPublic value={noticeState.postState} onChange={handlePostStateChange} />
            <BoardFixed value={noticeState.topFixFlag} onChange={handleTopFixFlagChange} />
            <BoardContent value={noticeState.content} onChange={handleContentChange} />

            <BoardEditFile value={noticeState.fileList} onChange={handleFileChange} />

            {/* 첨부파일 */}

            <div className='d-flex align-items-center justify-content-center border-top pt-3'>
              <Button type='submit' variant='primary' className='me-2'>
                등록
              </Button>
              <Button variant='secondary'>취소</Button>
            </div>
          </Form>
        </div>
      </main>
    </>
  );
};
