const options = {
  html2canvas: {
    width: window.innerWidth, // 웹 페이지의 너비를 100%로 설정
  },
};

export const PdfDownButton = ({ onClick }) => {
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <button onClick={onClick} className='bg-second rounded-3 text-white border-none btn-transition react-pdf-downBtn'>
        PDF 다운로드
        <i className='bi bi-filetype-pdf ms-2'></i>
      </button>
    </div>
  );
};
