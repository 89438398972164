import { atom } from 'recoil';

export const adminUserPopupState = atom({
  key: 'adminUserPopupState',
  default: {
    popupState: false,
    toastState: false,
    toastType: null,
  },
});
