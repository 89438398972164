import AsyncSelect from 'react-select/async';

/**
 * AdminSelectBox 컴포넌트는 학과와 과목을 선택하는 기능을 제공합니다.
 *
 * @component
 * @param {boolean} lectureIsLoading - 학과 옵션 로딩 상태
 * @param {Array} lectureOption - 학과 옵션 배열
 * @param {function} lectureOnChange - 학과 선택 시 호출되는 콜백 함수
 * @param {boolean} subjectIsLoading - 과목 옵션 로딩 상태
 * @param {Array} subjectOption - 과목 옵션 배열
 * @param {function} subjectOnChange - 과목 선택 시 호출되는 콜백 함수
 * @param {any} adminSelectState - 학과 선택전 과목 선택 디스에이블 상태
 * @param {boolean} adminSubSelectBoxQueryIsLoading - 관리자 하위 선택 상자 쿼리 로딩 상태
 * @returns {JSX.Element} AdminSelectBox 컴포넌트
 */
function AdminSelectBox({
  lectureIsLoading,
  lectureOption,
  lectureOnChange,
  lecturePlaceholder = '학과 선택',
  subjectIsLoading,
  subjectOption,
  subjectOnChange,
  subjectPlaceholder = '과목 선택',
  adminSelectState,
  adminSubSelectBoxQueryIsLoading,
}) {
  return (
    <div className='d-flex align-items-center w-100 ps-3'>
      <div className='d-flex align-items-center flex-glow-2'>
        <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
          학과
        </label>

        <AsyncSelect
          className='w-100'
          defaultInputValue={lecturePlaceholder || `학과 선택`}
          placeholder={lecturePlaceholder || `학과 선택`}
          isLoading={lectureIsLoading}
          cacheOptions
          defaultOptions={lectureOption}
          onChange={lectureOnChange}
        />
      </div>

      <div className='d-flex align-items-center ms-3 flex-glow-3'>
        <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
          과목
        </label>
        <AsyncSelect
          className='w-100'
          defaultInputValue={subjectPlaceholder || `과목 선택`}
          placeholder={subjectPlaceholder || `과목 선택`}
          isLoading={subjectIsLoading}
          cacheOptions
          defaultOptions={subjectOption}
          isDisabled={!adminSelectState && !adminSubSelectBoxQueryIsLoading}
          onChange={subjectOnChange}
        />
      </div>
    </div>
  );
}

export default AdminSelectBox;
