import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export const useGetTeacherQuery = () => {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const checkTeacher = decoded.role === 'TEACHER';
  const checkAdmin = decoded.role === 'ADMIN';
  const checkNotStudent = checkTeacher || checkAdmin;

  const response = useQueries({
    queries: [
      {
        // 교수자 - 홈/과제, 퀴즈 미채점
        queryKey: ['assignment', decoded],
        queryFn: () => getDataQuery('/teach/home/users/me/submission-summary'),
        enabled: checkTeacher,
      },
      {
        // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
        // subject 상위 select-box list
        queryKey: ['teacherSubjectTitle', decoded],
        queryFn: () => getDataQuery('/teach/me/courses'),
        enabled: checkNotStudent,
      },

      {
        // 교수자 - [홈] 과목리스트 (대쉬보드 학습 학생 진도율 셀렉트박스에 들어갈 정보 api)
        queryKey: ['teacherStudentProgress', decoded],
        queryFn: () => getDataQuery('/teach/home/users/me/enrollment/all'),
      },
    ],
  });

  const [assignment, teacherSubjectTitle, teacherStudentProgress] = response?.map((query) => query);
  const [assignmentData, teacherSubjectTitleData, teacherStudentProgressData] = response?.map(
    (query) => query?.data?.data
  );

  // 교수자 - 홈/과제, 퀴즈 미채점
  const { isLoading: assignmentIsLoading, isError: assignmentIsError } = assignment;

  // 교수자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
  // subject 상위 select-box list
  const { isLoading: teacherSubjectTitleIsLoading, isError: teacherSubjectTitleIsError } = teacherSubjectTitle;

  // 교수자 - [홈] 과목리스트 (대쉬보드 학습 학생 진도율 셀렉트박스에 들어갈 정보 api)
  const { isLoading: teacherStudentProgressIsLoading, isError: teacherStudentProgressIsError } = teacherStudentProgress;

  return {
    // 교수자 - 홈/과제, 퀴즈 미채점
    assignmentData,
    assignmentIsLoading,
    assignmentIsError,

    // 교수자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSubjectTitleData,
    teacherSubjectTitleIsLoading,
    teacherSubjectTitleIsError,

    // 교수자 - [홈] 과목리스트 (대쉬보드 학습 학생 진도율 셀렉트박스에 들어갈 정보 api)
    teacherStudentProgressData,
    teacherStudentProgressIsLoading,
    teacherStudentProgressIsError,
  };
};
