import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';

import { CardHeader, SpinnerLoading } from 'components/common';
import StudentProgressEmpty from 'components/common/dashboard/StudentProgressEmpty';
import JandiList from 'components/subject/JandiList';
import { useGetCommonApi, useGetTeacherQuery } from 'hooks';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { JANDI_CHANGER } from 'util/jandi/jandiUtil';

export function StudentProgress({ cardTitle }) {
  const loginState = useRecoilValue(loginUser);
  const [optionData, setOptionData] = useState([]);
  const [courseId, setCourseId] = useState(null);

  const { enrollmentData, enrollmentIsLoading, enrollmentIsError } = useGetCommonApi();
  const { teacherStudentProgressData } = useGetTeacherQuery();
  const {
    // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSelectBoxQueryData,
    teacherSelectBoxQueryIsLoading,
    teacherSelectBoxQueryIsError,
  } = useGetRequiredApi();

  // 교수자 - [홈] 과목 학생별 진행정보
  const { data: teacherStudentProcessRate, isLoading: teacherStudentProcessRateIsLoading } = useQuery({
    queryKey: ['teacherStudentProcessRate', loginState, courseId],
    queryFn: () => getDataQuery(`/teach/home/course/${courseId}/students/progress-info`),
    enabled: !!courseId,
    select: (data) => JANDI_CHANGER(JSON.parse(data?.data)),
  });

  const handleOnChange = (e) => {
    setCourseId(e?.value);
  };

  useEffect(() => {
    return () => {
      setCourseId(null);
    };
  }, []);

  useEffect(() => {
    return setOptionData(
      teacherSelectBoxQueryData?.map((item) => ({
        value: item.course_id,
        label: item.course_name,
      }))
    );
  }, [teacherSelectBoxQueryData]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        teacherSelectBoxQueryData?.map((item) => ({
          value: item.course_id,
          label: item.course_name,
        }))
      );
    });

  return (
    <div className='col-12'>
      <div className='card recent-sales overflow-auto p-20'>
        <div className=''>
          <CardHeader title='학습 학생 진도율'>{cardTitle}</CardHeader>

          <div className='d-flex align-items-center'>
            <label htmlFor='teacherStudentProgress' className='white-nowrap mx-2'>
              과목
            </label>
            <AsyncSelect
              className='w-100'
              defaultInputValue='과목 선택'
              placeholder='과목 선택'
              isLoading={teacherSelectBoxQueryIsLoading}
              cacheOptions
              defaultOptions={optionData}
              onChange={handleOnChange}
              loadOptions={promiseOptions}
            />
          </div>

          <div>
            <Table>
              <tbody>
                {/* 받아온 데이터 뿌려주기 */}
                {teacherStudentProcessRateIsLoading ? (
                  <tr>
                    <td className='position-relative py-5'>
                      <SpinnerLoading />
                    </td>
                  </tr>
                ) : teacherStudentProcessRate && teacherStudentProcessRate?.length > 0 ? (
                  teacherStudentProcessRate?.map((item) => (
                    <>
                      <tr key={item.student_id} className='border-bottom mb-2'>
                        <td className='py-20 text-start --Palette-DarkGray-80 fz-18 fw-bold' scope='row'>
                          {item.name}
                        </td>
                        <td className='py-20 text-end --Palette-Gray-70 fz-12 align-middle'>주차완료</td>
                        <td className='py-20 w-50 pe-3'>
                          <ul className='d-flex justify-content-between'>
                            {item?.progressResDtoList?.map((el, index) => (
                              <JandiList type={el.state}>{index + 1}</JandiList>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <>
                    <StudentProgressEmpty />
                  </>
                )}
                {}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
