import BreadCrumb from 'components/shared/BreadCrumb';
import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { adminReportState } from 'recoil/report/admin/adminReportState';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { studentPagination } from 'recoil/report/studentPagination';
import { teacherReportState } from 'recoil/report/teacher/teacherReportState';
import { teacherStudentState } from 'recoil/report/teacher/teacherStudentState';

export const ReportLayout = () => {
  const setReportState = useSetRecoilState(studentReportState);
  const setAdminReport = useSetRecoilState(adminReportState);
  const setReacherReportState = useSetRecoilState(teacherReportState);
  const setStudentState = useSetRecoilState(teacherStudentState);

  const setCurrentPage = useSetRecoilState(studentPagination);

  useEffect(() => {
    setCurrentPage(1);
    return () => {
      setReportState(null);
      setReacherReportState(null);
      setStudentState(null);
      setAdminReport(null);
      setCurrentPage(1);
    };
  }, []);

  return (
    <>
      <main id='main' className={`main ${!'subjectId' && 'subject-icon-backdrop'}`}>
        <BreadCrumb home={'/report'} sublink={'/report'} title={'Performance Report'} subTitle={'KBU 성과보고서'} />
        <Outlet />
      </main>
    </>
  );
};
