import { atom } from 'recoil';

export const subjectValueState = atom({
  key: 'subjectValueState',
  default: {
    subjectId: '',
    courseId: '',
    subjectName: '',
  },
});
