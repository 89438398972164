import { atom } from 'recoil';

export const subjectTeacherState = atom({
  key: 'subjectTeacherState',
  default: {
    courseId: '',
    moduleId: '',
    assignment: '',
    studentLmsUserIds: '',
    course_title: '',
    title: '',
  },
});
