import { CardLayout } from 'components/shared/card/CardLayout';
import { CardSectionTitle } from 'components/shared/card/CardSectionTitle';
import { usePDFDownLoad } from 'hooks/report/usePDFDownLoad';
import { useReport } from 'hooks/report/useReport';
import { useReportQuery } from 'hooks/report/useReportQuery';
import { ReportLectureItem } from 'pages/student/report/ReportLectureItem';
import { PdfDownButton } from 'pages/student/report/common/PdfDownButton';
import { ReportFooter } from 'pages/student/report/common/ReportFooter';
import { ReportHeadTitle } from 'pages/student/report/common/ReportHeadTitle';
import { ReportSelectLayout } from 'pages/student/report/common/ReportSelectLayout';
import { handleNumberGarde, teacherReportDataChanger } from 'pages/student/report/data';
import {
  reportChartENLeftAxis,
  reportChartLeftAxis,
  reportChartTeacherColors,
} from 'pages/student/report/reportConstant';
import { ReportLiberalItem } from 'pages/student/report/student/ReportLiberalItem';
import { ReportStudentChart } from 'pages/student/report/student/ReportStudentChart';
import { ReportTeacherScoreItem } from 'pages/student/report/student/ReportTeacherScoreItem';
import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { adminReportState } from 'recoil/report/admin/adminReportState';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { teacherReportState } from 'recoil/report/teacher/teacherReportState';

export const ReportTeacher = () => {
  const user = useRecoilValue(loginUser);
  const { semesterSelector, departmentSelector, adminDepartmentSelector } = useReport();
  const [reportState, setReportState] = useRecoilState(studentReportState);
  const setAdminReport = useSetRecoilState(adminReportState);
  const [reacherReportState, setReacherReportState] = useRecoilState(teacherReportState);
  const { targetRef, flexRef, liberalRef, handleClickDownPDF } = usePDFDownLoad();

  const {
    교수학과_정보,
    선생_학과_통계_일_조회_정보,
    선생_학과_통계_일_조회_로딩,
    선생_학과_통계_이_조회_정보,
    선생_학과_통계_이_조회_로딩,
    선생_두번째_차트_정보,
    선생_두번째_차트_로딩,
  } = useReportQuery();

  useEffect(() => {
    return () => {
      setReportState(null);
      setAdminReport(null);
      setReacherReportState(null);
    };
  }, []);

  return (
    <section>
      <div className='row'>
        <div className='col'>
          <div className='position-relative'>
            {/* 셀렉트 박스 */}
            <ReportSelectLayout>
              <div className='d-flex align-items-center' style={{ width: '60%' }}>
                {semesterSelector(true, setReportState)}
              </div>
              {user?.role === 'TEACHER' && departmentSelector(true, setReacherReportState)}
              {user?.role === 'ADMIN' && adminDepartmentSelector(true, setAdminReport)}
            </ReportSelectLayout>

            {/* {true && (
              <div className='card p-3 w-100' style={{ height: '100px' }}>
                <SpinnerLoading />
              </div>
            )} */}
            {reacherReportState ? (
              <>
                <PdfDownButton
                  onClick={() => handleClickDownPDF('w-full d-flex justify-content-center align-items-center')}
                />
                <div className='' ref={targetRef}>
                  {/* 타이틀 섹션 */}
                  <ReportHeadTitle
                    visible={false}
                    title={교수학과_정보?.data?.sub_division_name}
                    name={교수학과_정보?.data?.sub_division_name}
                    onClick={handleClickDownPDF}
                    no={'학번'}
                    department={'기본강의 심화'}
                    semesterData={reportState?.label}
                  />
                  <CardLayout title='1. 세부 학습목표 성취율'>
                    {/* 프로그레스바 섹션 */}
                    <section>
                      <CardSectionTitle index='1' title='성공적인 대학생활' />
                      <table className='w-full'>
                        <thead>
                          <tr className='fz-14'>
                            <th className='text-start ps-0' colSpan={2}>
                              세부학습목표
                            </th>
                            <th className='text-start'>성취율</th>
                            <th className='text-center'>상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ReportLectureItem
                            title='교육목표 이해도'
                            percent={선생_학과_통계_일_조회_정보?.data?.first_one}
                            content='학생은 경복대학교의 설립이념과 교육목표 이해한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.first_one_status}
                          />
                          <ReportLectureItem
                            title='대학 자원 활용'
                            percent={선생_학과_통계_일_조회_정보?.data?.first_two}
                            content='학생은 성공적인 대학생활을 위하여 대학의 자원을 적절하게 활용한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.first_two_status}
                          />
                          <ReportLectureItem
                            title='진단검사 실시'
                            percent={선생_학과_통계_일_조회_정보?.data?.first_three}
                            content='학생은 개인 맞춤형 성공지원을 위한 진단검사에 참여한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.first_three_status}
                          />
                          <ReportLectureItem
                            title='협력적인 관계형성'
                            percent={선생_학과_통계_일_조회_정보?.data?.first_four}
                            content='학생은 지도교수 및 대학 구성원과 협력적인 관계를 형성한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.first_four_status}
                          />
                          <ReportLectureItem
                            title='행사참여'
                            percent={선생_학과_통계_일_조회_정보?.data?.first_five}
                            content='학생은 진로설계를 위한 추천하는 활동 및 행사에 참여 한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.first_five_status}
                          />
                          <ReportLectureItem
                            title='트랙설계'
                            percent={선생_학과_통계_일_조회_정보?.data?.first_six}
                            content='학생은 진로설계에 따른 자신의 교육 트랙을 설계 한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.first_six_status}
                          />
                        </tbody>
                      </table>
                    </section>

                    <section>
                      <CardSectionTitle index='2' title='미래역량 향상' />
                      <table className='w-full'>
                        <thead>
                          <tr>
                            <th className='text-start ps-0' colSpan={2}>
                              세부학습목표
                            </th>
                            <th className='text-start'>성취율</th>
                            <th className='text-center'>상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ReportLectureItem
                            title='교육목표 이해도'
                            percent={선생_학과_통계_일_조회_정보?.data?.second_one}
                            content='학생은 사회인으로서 요구되는 기초소양과 의사소통능력을 함양한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.second_one_status}
                          />
                          <ReportLectureItem
                            title='대학 자원 활용'
                            percent={선생_학과_통계_일_조회_정보?.data?.second_two}
                            content='학생은 사회인으로서 필요한 필수교육을 이수한다.'
                            type={선생_학과_통계_일_조회_정보?.data?.second_two_status}
                          />
                          <ReportLectureItem
                            title='진단검사 실시'
                            percent={선생_학과_통계_일_조회_정보?.data?.second_three}
                            content='학생은 미래사회 기반기술인 AI를 이해하고 활용 할 수 있는 능력을 갖춘다.'
                            type={선생_학과_통계_일_조회_정보?.data?.second_three_status}
                          />
                        </tbody>
                      </table>
                    </section>
                  </CardLayout>
                  <CardLayout title='2. 과제수행도'>
                    {/* 차트 섹션 */}
                    <section>
                      <CardSectionTitle index='1' title='기초학습능력진단 검사' />
                      <div ref={flexRef} className='d-flex flex-sm-row flex-column px-5 overflow-hidden'>
                        {선생_두번째_차트_정보?.data?.map((item) => (
                          <div className='w-full d-flex justify-content-center align-items-center'>
                            <ReportStudentChart
                              maxValue={item.statics_subject_name.includes('영어') ? 60 : 100}
                              LeftAxis={
                                item.statics_subject_name.includes('영어') ? reportChartENLeftAxis : reportChartLeftAxis
                              }
                              chartTitle={
                                item.statics_subject_name.includes('영어')
                                  ? `${item.statics_subject_name} - 60점 만점`
                                  : item.statics_subject_name
                              }
                              data={teacherReportDataChanger(item.subdivision_score, item.grade_score)}
                              colors={reportChartTeacherColors}
                            />
                          </div>
                        ))}
                      </div>
                    </section>

                    {/* 테이블 */}
                    <div className='my-5'>
                      <Table className='w-full'>
                        <thead>
                          <tr className='fz-14'>
                            <th className='text-start align-center' style={{ width: '20%' }}>
                              <h4 className='py-3'>구분</h4>
                            </th>
                            <th className='white-nowrap'>
                              <h4 className='py-3'>참여율 &#40;참여학생 / 학과학생&#41;</h4>
                            </th>
                            <th className='bg-table-teacher-gray'>
                              <h4 className='py-3'>
                                학과 평균 점수 <span>★</span>
                              </h4>
                            </th>
                            <th>
                              <h4 className='py-3'>1학년 평균 점수</h4>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {선생_두번째_차트_정보?.data?.map((item) => (
                            <ReportTeacherScoreItem
                              title={item.statics_subject_name}
                              percent={handleNumberGarde(item.attendance_percent)}
                              average_score={item.subdivision_score}
                              average_grade_score={item.grade_score}
                              submit={item.submit_participants}
                              total={item.total_participants}
                            />
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    {/* 교양 퍼센트 */}
                    <section>
                      <CardSectionTitle index='2' title='교양 교육' />
                      <div ref={liberalRef} className='mt-4 d-block justify-content-evenly d-lg-flex gap-3'>
                        <ReportLiberalItem
                          title='성폭력 교육'
                          content={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_sexual_violence_prevent_percent > 60
                              ? 'Y'
                              : 'N'
                          }
                          state={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_sexual_violence_prevent_percent > 60
                              ? 'Y'
                              : 'N'
                          }
                        >
                          {선생_학과_통계_이_조회_정보?.data?.sub_division_sexual_violence_prevent_percent}%
                        </ReportLiberalItem>
                        <ReportLiberalItem
                          title='중독예방 교육'
                          content={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_addiction_prevent_percent > 60 ? 'Y' : 'N'
                          }
                          state={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_addiction_prevent_percent > 60 ? 'Y' : 'N'
                          }
                        >
                          {선생_학과_통계_이_조회_정보?.data?.sub_division_addiction_prevent_percent}%
                        </ReportLiberalItem>
                        <ReportLiberalItem
                          title='가정폭력교육'
                          content={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_domestic_violence_prevent_percent > 60
                              ? 'Y'
                              : 'N'
                          }
                          state={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_domestic_violence_prevent_percent > 60
                              ? 'Y'
                              : 'N'
                          }
                        >
                          {선생_학과_통계_이_조회_정보?.data?.sub_division_domestic_violence_prevent_percent}%
                        </ReportLiberalItem>
                        <ReportLiberalItem
                          title='안전 교육'
                          content={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_safety_training_percent > 60 ? 'Y' : 'N'
                          }
                          state={
                            선생_학과_통계_이_조회_정보?.data?.sub_division_safety_training_percent > 60 ? 'Y' : 'N'
                          }
                        >
                          {선생_학과_통계_이_조회_정보?.data?.sub_division_safety_training_percent}%
                        </ReportLiberalItem>
                      </div>
                    </section>
                  </CardLayout>

                  <ReportFooter />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
