import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export function BoardFile({ value, onChange }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const findFileSize = acceptedFiles.find((el) => el.size > 10 * 1024 ** 2);

      if (findFileSize) return alert(`파일 크기가 10MB가 넘습니다.`);
      onChange(acceptedFiles);
    },
    [onChange]
  );

  const handleDeleteListItem = useCallback(
    (name) => {
      const filterData = value.filter((el) => el.name !== name);
      onChange(filterData);
    },
    [onChange, value]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/msword': ['.doc', '.docx'],
      'application/x-hwp': ['.hwp'],
    },
  });
  return (
    <>
      <div className='d-flex py-2'>
        <label htmlFor=''>첨부파일</label>
        <input type='file' name='' id='' {...getInputProps()} />

        {/* 이미지 드랍존 박스 */}
        <div className='w-100'>
          <div
            className={`rounded border w-100 p-3 text-center ${isDragActive && 'dropzone-isDragActive'}`}
            style={{ background: '#F8F9FA' }}
            {...{ ...getRootProps() }}
          >
            <i className='bi bi-inbox text-blue-primary' style={{ fontSize: '48px' }}></i>
            <p className='mb-2 mt-3 fz-16' style={{ color: '#212529' }}>
              Click or drag file to this area to upload
            </p>
            <p className='fz-14' style={{ color: '#6C757D' }}>
              Support for a single or bulk upload. Maximum file size 10MB.
            </p>
          </div>

          {/* 파일 리스트 */}
          <ul className='mt-2'>
            {value?.map((item) => (
              <li key={item.name} className='trash-hover-gray' style={{ color: '#1243BF' }}>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <i className='bi bi-file-earmark me-2'></i>
                    <p>{item.name}</p>
                  </div>
                  <button
                    onClick={() => {
                      handleDeleteListItem(item.name);
                    }}
                    type='button'
                    className='btn btn-style-none'
                  >
                    <i className='bi bi-trash'></i>
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
