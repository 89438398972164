import { date, day, month, randomColors, year } from 'assets/constant';
import moment from 'moment';
import { StreamEmpty } from '.';
import { CardHeader } from '../CardHeader';

export function DashboardStream({ data, isLoading }) {
  return (
    <>
      {/* 활동 스트림 */}
      <div className='card'>
        <div className='p-20'>
          <div className='border-bottom pb-4'>
            <CardHeader title='활동스트림'>오늘</CardHeader>
            <h3 className='fz-24 fw-bold'>
              <p className='--Palette-DarkGray-80'>
                {year}년 {month}월 {date}일
              </p>
              <p className='color-primary mt-2'>{day}</p>
            </h3>
          </div>

          <div className='activity mt-4'>
            {/* {isLoading ? <StreamEmpty /> : ''} */}
            {data && data.length > 0 ? (
              data?.map((item, index) => {
                const momentDate = moment(item.until_date);
                const momentYear = momentDate.format('YYYY');
                const momentMonth = momentDate.format('MM');
                const momentDay = momentDate.format('DD');
                const momentTime = momentDate.format('a hh:mm');
                const momentReplaceTime = momentTime.includes('am')
                  ? momentTime.replace('am', '오전')
                  : momentTime.replace('pm', '오후');

                const momentDiff = moment(new Date()).diff(momentDate);
                const momentHours = moment.duration(momentDiff).hours();
                const momentMinutes = moment.duration(momentDiff).minutes();

                return (
                  <div key={(item, index)} className='activity-item d-flex'>
                    <div className='activite-label'>
                      {momentHours}시{momentMinutes}분
                    </div>
                    <i
                      className='bi bi-circle-fill activity-badge align-self-start'
                      // 랜덤 색상
                      style={{ color: randomColors() }}
                    ></i>
                    <div className='activity-content mb-3'>
                      <span className='fw-bold'>
                        <span className='me-1'>&#60;{item.assignment_title}&#62;</span>
                        {item.enrollment_title}
                      </span>
                      <p className='endDate mt-3'>
                        마감일
                        <span className='ms-1'>{momentYear}년</span>
                        <span className='ms-1'>{momentMonth}월</span>
                        <span className='ms-1'>{momentDay}일</span>
                        <span className='ms-1'>{momentReplaceTime}</span>
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <StreamEmpty />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
