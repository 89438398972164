/**
 *
 * @param {()=>{}} onClick 클릭 이벤트
 * @param {string} type 인풋의 타입상태
 * @returns
 */
export function InputAffix({ onClick, type }) {
  return (
    <button type='button' onClick={onClick} className='btn btn-style-none position-absolute top-right-50 w-auto'>
      <i className={`${type === 'text' ? 'bi bi-eye' : 'bi bi-eye-slash'}`}></i>
    </button>
  );
}
