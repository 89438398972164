import { useMutation, useQueryClient } from '@tanstack/react-query';
import { delDataQuery } from 'api/delQuery';
import { patchDataQuery } from 'api/patchQuery';
import { getSessionStorage } from 'assets/constant';
import { jwtDecode } from 'jwt-decode';

export function usePatchSupporting(path) {
  const token = getSessionStorage();
  const decoded = jwtDecode(token);

  const queryClient = useQueryClient();

  // 글 삭제 로직
  const noticeDeleteMutation = useMutation({
    mutationFn: (id) => patchDataQuery(`/help/notification/${id}`),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['notice&news', decoded] });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // FAQ 삭제 로직
  const faqDeleteMutation = useMutation({
    mutationFn: (id) => patchDataQuery(`/help/faq/${id}`),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['FAQ', decoded] });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // 자료실 삭제
  const referenceDeleteMutation = useMutation({
    mutationFn: (id) => patchDataQuery(`/help/data-room/${id}`),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['reference', decoded] });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // 글 수정 미디어 삭제 로직
  const noticeEditFileDeleteMutation = useMutation({
    mutationFn: (id) => delDataQuery(`/attach/${id}`),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ['notice&newsDetail', decoded, path] });
      queryClient.invalidateQueries({ queryKey: ['FAQDetail', decoded, path] });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return {
    // 공지사항 삭제
    noticeDeleteMutation,
    // faq 삭제
    faqDeleteMutation,
    // 자료실 삭제
    referenceDeleteMutation,

    // 수정 => 미디어 삭제
    noticeEditFileDeleteMutation,
  };
}
