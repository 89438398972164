export const studentReportDataChanger = (나의_점수, 학과_평균, 일학년_평균) => {
  const reportData = [
    {
      title: '나의 점수',
      '나의 점수': 나의_점수,
    },
    {
      title: '학과 평균',
      '학과 평균': 학과_평균,
    },
    {
      title: '1학년 평균',
      '1학년 평균': 일학년_평균,
    },
  ];

  return reportData;
};
export const teacherReportDataChanger = (학과_평균, 일학년_평균) => {
  const reportData = [
    {
      title: '학과 평균',
      '학과 평균': 학과_평균,
    },
    {
      title: '1학년 평균',
      '1학년 평균': 일학년_평균,
    },
  ];
  return reportData;
};
export const studentChartData = [
  {
    title: '나의 점수',
    '나의 점수': 75,
  },
  {
    title: '학과 평균',
    '학과 평균': 75,
  },
  {
    title: '1학년 평균',
    '1학년 평균': 75,
  },
];

export const teacherChartData = [
  {
    title: '학과 평균',
    '학과 평균': 30,
  },
  {
    title: '1학년 평균',
    '1학년 평균': 95,
  },
];

export const reportStudentTRUE = true;
export const studentTableData = {
  my_score: 50,
  average_score: 40,
  department_average_score: 60,
};
export const sdutentLiberalData = [
  { id: '1', title: '교양1', content: '참여 / 이수', state: 'PASS' },
  { id: '2', title: '교양2', content: '미참여 / 미이수', state: 'FAIL' },
  { id: '3', title: '교양3', content: '참여 / 이수', state: 'PASS' },
];

export const studentLiberaChanger = (obj) => {
  try {
    const { aim, want_to_say, serve, serve_term, serve_place, serve_purpose } = obj;

    return [
      {
        id: '1',
        title: '대학생활 중 나의 목표',
        content: aim,
        border: true,
      },
      {
        id: '2',
        title: '내 자신에게 하고 싶은 말',
        content: want_to_say,
        border: true,
      },
      {
        id: '3',
        title: '후배들에게 조언',
        content: '',
        border: true,
      },
      {
        id: '4',
        title: '봉사계획서',
        subtitle: '봉사영역',
        content: serve,
      },
      {
        id: '5',
        subtitle: '봉사활동 기간',
        content: serve_term,
      },
      {
        id: '6',
        subtitle: '봉사활동 기관(장소)',
        content: serve_place,
      },
      {
        id: '7',
        subtitle: '봉사활동 목적 및 내용',
        content: serve_purpose,
      },
    ];
  } catch (error) {
    return [];
  }
};

const adminObjFn = (arr, text) => {
  return arr?.find((el) => el.subdivision_subject_name.includes(text));
};
export const adminAllChart = (obj) => {
  try {
    const { grade_score_statics, sub_division_subject_attendances } = obj;

    const newArr = [
      {
        id: 0,
        data: adminObjFn(sub_division_subject_attendances, '국어'),
        average: grade_score_statics?.kor_score,
      },
      {
        id: 1,
        data: adminObjFn(sub_division_subject_attendances, '사회'),
        average: grade_score_statics?.social_score,
      },
      {
        id: 2,
        data: adminObjFn(sub_division_subject_attendances, '과학'),
        average: grade_score_statics?.science_score,
      },
      {
        id: 3,
        data: adminObjFn(sub_division_subject_attendances, '수학'),
        average: grade_score_statics?.math_score,
      },
      {
        id: 4,
        data: adminObjFn(sub_division_subject_attendances, '영어'),
        average: grade_score_statics?.eng_score,
      },
    ];

    return newArr;
  } catch (error) {
    return [];
  }
};
export const studentLiberalData2 = [
  {
    id: '1',
    title: '대학생활 중 나의 목표',
    content: `<ul>
    <li>Lorem ipsum dolor sit amet,</li>
    <li>consectetur adipiscing elit.</li>
    <li>Integer nec odio.</li>
    </ul>`,
  },
  {
    id: '2',
    title: '내 자신에게 하고 싶은 말',
    content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis tortor, consequat efficitur mi congue a. Curabitur cursus, ipsum ut lobortis sodales, enim arcu pellentesque lectus
    ac suscipit diam sem a felis. Cras sapien ex, blandit eu dui et suscipit gravida nunc. Sed sed est quis dui.</p>`,
  },
  {
    id: '3',
    title: '후배들에게 조언',
    content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis tortor, consequat efficitur mi congue a. Curabitur cursus, ipsum ut lobortis sodales, enim arcu pellentesque lectus
    ac suscipit diam sem a felis. Cras sapien ex, blandit eu dui et suscipit gravida nunc. Sed sed est quis dui.</p>`,
  },
  {
    id: '4',
    title: '봉사계획서',
    subtitle: '봉사영역',
    content: `<p>지역사회 봉사</p>`,
  },
  {
    id: '5',
    subtitle: '봉사활동 기간',
    content: `<p>2024년 7월 23일</p>`,
  },
  {
    id: '6',
    subtitle: '봉사활동 기관(장소)',
    content: `장소 주소`,
  },
  {
    id: '7',
    subtitle: '봉사활동 목적 및 내용',
    content: `봉사 시간`,
  },
];

export const reportPDFOptions = {
  page: {
    margin: {
      top: 10,
      right: 25,
      left: 25,
      bottom: 10,
    },
  },
  method: 'save',
  canvas: {
    windowWidth: 1920,
    innerWidth: 1920,
  },
  overrides: {
    putOnlyUsedFonts: true,
    html2canvas: {
      // windowWidth: 297, // 웹 페이지의 너비를 고정값으로 설정
      // scale: window.innerWidth / 1920, // 렌더링에 사용할 스케일을 뷰포트 너비와 고정값의 비율로 설정
    },
  },
};

export const handleNumberGarde = (number) => {
  if (number === undefined || number === null || isNaN(number)) return 0;

  return parseInt(number, 10);
};

export const adminDepartmentList = [
  { id: 1, title: '국어국문학과54', 국어국문학과54: '64' },
  { id: 2, title: '간호학과78', 간호학과78: '64' },
  { id: 3, title: '반려동물고건가952', 반려동물고건가952: '64' },
  { id: 4, title: '시간디자인75', 시간디자인75: '64' },
  { id: 5, title: '의료미용관74', 의료미용관74: '64' },
  { id: 6, title: '국어국문학과1', 국어국문학과1: '64' },
  { id: 7, title: '간호학과2', 간호학과2: '64' },
  { id: 8, title: '반려동물고건가3', 반려동물고건가3: '64' },
  { id: 9, title: '시간디자인4', 시간디자인4: '64' },
  { id: 10, title: '의료미용관5', 의료미용관5: '64' },
  { id: 11, title: '국어국문학과6', 국어국문학과6: '64' },
  { id: 21, title: '간호학과7', 간호학과7: '64' },
  { id: 31, title: '반려동물고건가8', 반려동물고건가8: '64' },
  { id: 41, title: '시간디자인11', 시간디자인11: '64' },
  { id: 51, title: '의료미용관12', 의료미용관12: '64' },
  { id: 61, title: '국어국문학과15', 국어국문학과15: '64' },
  { id: 71, title: '간호학과17', 간호학과17: '64' },
  { id: 81, title: '반려동물고건가18', 반려동물고건가18: '64' },
  { id: 91, title: '시간디자인19', 시간디자인19: '64' },
  { id: 101, title: '의료미용관20', 의료미용관20: '64' },
];

export const chartArrMap = (arr) => {
  return arr.map((el) => ({ title: el.title }));
};
export const chartArrKeyMap = (arr) => {
  return arr.map((el) => el.title);
};

export const reportAdminChartsTitle = {
  en: '기초학습능력 진단 - 영어',
  ko: '기초학습능력 진단 - 국어',
  math: '기초학습능력 진단 - 수학',
  science: '기초학습능력 진단 - 과학',
  social: '기초학습능력 진단 - 사회',
};

export const reportDummyData = [
  {
    subdivision_score: 0,
    grade_score: 0,
    individual_score: 0,
    statics_subject_name: '기초학습능력 진단 검사',
  },
  {
    subdivision_score: 0,
    grade_score: 0,
    individual_score: 0,
    statics_subject_name: '기초학습능력 진단 검사',
  },
  {
    subdivision_score: 0,
    grade_score: 0,
    individual_score: 0,
    statics_subject_name: '기초학습능력 진단 검사',
  },
];
