import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchDataQuery } from 'api/patchQuery';
import { useRecoilValue } from 'recoil';
import { adminQueryKeyState } from 'recoil/adminpopup/adminQueryKeyState';

export function useAdminPatchApi(userNumber, userRole) {
  const queryKeyState = useRecoilValue(adminQueryKeyState);
  const queryClient = useQueryClient();

  const patchUserPasswordMutation = useMutation({
    mutationFn: () => patchDataQuery(`/admin/password?id=${userNumber}`),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['AllUser', queryKeyState.role, queryKeyState.currentPage, queryKeyState.username]);
    },
  });

  const patchData = {
    userNumber,
    role: userRole,
  };

  const patchUserRoleMutation = useMutation({
    mutationFn: () => patchDataQuery(`/admin/role`, patchData),
    // mutationFn: () => patchDataQuery(`/admin/password?id=${userNumber}`, patchData),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['AllUser', queryKeyState.role, queryKeyState.currentPage, queryKeyState.username]);
    },
  });

  return {
    // 유저 패스워드 초기화
    patchUserPasswordMutation,

    // 유저 권한 변경
    patchUserRoleMutation,
  };
}
