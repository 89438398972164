import CryptoJS from 'crypto-js';

const secretKey = CryptoJS.enc.Hex.parse(process.env.REACT_APP_AES_SECRETKEY)
const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_AES_IV)

//암호화export 
export const encrypt = (data) => {

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {iv: iv});
    let result = encrypted.toString()
    return encodeURIComponent(result)
}

//복호화export 
export const decrypt = (encrypted) => {
    try {
        var decrypted = CryptoJS.AES.decrypt(encrypted, secretKey, {iv: iv});
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    } catch {
        return {}
    }
}