import { Button } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { adminUserPopupState } from 'recoil/adminpopup/adminUserSettingPopup';
import { checkIconType } from 'util/popupCheck';

/**
 * AdminUserProfilePopup 함수는 어드민 유저 프로필 팝업을 렌더링합니다.
 * @param {string} title - 팝업의 제목
 * @param {React.ReactNode} children - 팝업의 HTML 내용
 * @param {'danger' | 'shield'} iconType - 팝업의 색상과 아이콘 타입 ('danger' 또는 'shield')
 * @param {Function} successMutation - 확인 버튼을 클릭했을 때 실행할 콜백 함수
 * @returns {React.ReactNode} 팝업 컴포넌트
 */
export function AdminUserProfilePopup({ title, children, iconType, successMutation }) {
  const [popupState, setPopupState] = useRecoilState(adminUserPopupState);
  const checkType = checkIconType(iconType);
  const path = window.location.pathname.split('/')[3];

  const handleClickBtn = async () => {
    try {
      successMutation.mutate();
      setPopupState({
        popupState: false,
        toastState: true,
      });
    } catch (error) {
      console.error('error', error);
    }
    //   successMutation.mutate(path);
  };

  const handleClosePopup = () => {
    setPopupState({
      ...popupState,
      popupState: false,
    });
  };
  return (
    <>
      <div onClick={handleClosePopup} className='popup-backdrop'></div>
      <section className='popup-container text-center'>
        <div
          className='popup-icon-state m-auto d-flex justify-content-center align-items-center fz-22 mb-4'
          style={{ background: checkType.background, color: checkType.color }}
        >
          <i className={`bi ${checkType.icon}`}></i>
        </div>
        <h3 className='mb-4 fz-24 fw-bold'>{title}</h3>
        <div className='mb-5 fz-16'>{children}</div>

        <div className='d-flex justify-content-center align-items-center'>
          <Button
            onClick={handleClosePopup}
            variant='outline-light'
            className='text-body border border-secondary me-2 py-3'
            style={{ flex: 1 }}
          >
            취소
          </Button>
          <Button
            onClick={handleClickBtn}
            variant='danger'
            className='py-3 popupBtn-background-danger'
            style={{ flex: 1, background: checkType.color }}
          >
            확인
          </Button>
        </div>
      </section>
    </>
  );
}
