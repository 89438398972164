/**
 *
 * @param {number} activeIndex 액티브 될 숫자
 * @returns
 */
export function SubjectBtnList({ activeIndex = 0, setFilterIndex, setFilterData, subjectMainData }) {
  const handleBtnClick = (index, type) => {
    setFilterIndex(index);

    if (type === 'all') {
      setFilterData(subjectMainData);
    }
    if (type === 'completed') {
      const completedItems = subjectMainData.map((el) => {
        return { ...el, items: el.items.filter((item) => item.completed === true) };
      });
      setFilterData(completedItems);
    }
    if (type === 'incompleted') {
      const incompletedItems = subjectMainData.map((el) => {
        return { ...el, items: el.items.filter((item) => item.completed !== true) };
      });
      setFilterData(incompletedItems);
    }
  };

  const reduceFn = (arr) => {
    return arr.reduce((acc, current) => acc + current, 0);
  };

  const allDataLength = reduceFn(subjectMainData.map((module) => module.items.length));
  const completedDataLength = reduceFn(
    subjectMainData.map((module) => module.items.filter((el) => el.completed === true).length)
  );
  const incompletedLength = reduceFn(
    subjectMainData.map((module) => module.items.filter((el) => el.completed !== true).length)
  );

  return (
    <ul className='d-flex '>
      <li className='me-3 '>
        <button
          onClick={() => {
            handleBtnClick(0, 'all');
          }}
          className={`btn btn-style-none btn-filter-btn ${activeIndex === 0 && 'btn-filter-active'}`}
        >
          전체 &#40;{allDataLength}&#41;
        </button>
      </li>
      <li className='me-3'>
        <button
          onClick={() => {
            handleBtnClick(1, 'completed');
          }}
          className={`btn btn-style-none btn-filter-btn ${activeIndex === 1 && 'btn-filter-active'}`}
        >
          완료 &#40;{completedDataLength}&#41;
        </button>
      </li>
      <li className='me-3 '>
        <button
          onClick={() => {
            handleBtnClick(2, 'incompleted');
          }}
          className={`btn btn-style-none btn-filter-btn ${activeIndex === 2 && 'btn-filter-active'}`}
        >
          미완료 &#40;{incompletedLength}&#41;
        </button>
      </li>
    </ul>
  );
}
