import BreadCrumb from 'components/shared/BreadCrumb';
import { Outlet } from 'react-router-dom';

export function AttendanceLayout() {
  return (
    <>
      <main id='main' className={`main ${!'subjectId' && 'subject-icon-backdrop'}`}>
        <BreadCrumb
          home={'/attendance'}
          sublink={'/attendance'}
          title={'Weekly Online Progress'}
          subTitle={'온라인 주차현황'}
        />
        <Outlet />
      </main>
    </>
  );
}
