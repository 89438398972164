import { JANDI_ARR_LENGTH, JANDI_UNLOCKED } from 'assets/constant';

/**
 * Jandi 에러를 처리하는 함수입니다.
 * @param {Object} error - Jandi 에러 객체
 * @returns {boolean} - Jandi 에러 메시지가 'not found jandi info' 또는 'failed to convert jandi info'인 경우 true를 반환합니다.
 */
export const jandiErrorHandle = (error) => {
  return error?.errorMessage === 'not found jandi info' || error?.errorMessage === 'failed to convert jandi info';
};

/**
 * JANDI_CHANGER 함수는 학생 데이터를 변경하여 반환하는 함수입니다.
 * 학생 데이터의 progressResDtoList의 길이를 확인하고, 부족한 경우 추가적인 데이터를 생성하여 학생 데이터에 추가합니다.
 *
 * @param {Array} studentData - 학생 데이터 배열
 * @returns {Array} 변경된 학생 데이터 배열
 */
export function JANDI_CHANGER(studentData) {
  return studentData?.map((student) => {
    const progressLength = student.progressResDtoList.length;

    if (progressLength < JANDI_ARR_LENGTH) {
      const additionalProgress = Array.from({ length: JANDI_ARR_LENGTH - progressLength }, (_, index) => ({
        position: progressLength + index + 1,
        state: JANDI_UNLOCKED,
      }));
      student.progressResDtoList = student.progressResDtoList.concat(additionalProgress);
    }

    return student;
  });
}

/**
 * 학생의 수강 과정 데이터를 변경하는 함수입니다.
 * 주어진 수강 과정 데이터 배열을 순회하며, 각 과정의 진행 상태를 확인하고 필요한 경우 추가 진행 상태를 생성합니다.
 *
 * @param {Array} courseData - 수강 과정 데이터 배열
 * @returns {Array} 변경된 수강 과정 데이터 배열
 */
export function STUDENT_JANDI_CHANGER(courseData) {
  return courseData?.map((course) => {
    const progressLength = course.course_progress_list.length;

    if (progressLength < JANDI_ARR_LENGTH) {
      const additionalProgress = Array.from({ length: JANDI_ARR_LENGTH - progressLength }, (_, index) => ({
        position: progressLength + index + 1,
        state: JANDI_UNLOCKED,
      }));
      course.course_progress_list = course.course_progress_list.concat(additionalProgress);
    }

    return course;
  });
}
