/**
 *
 * @param {NodeElement} children HTML 엘리먼트
 * @param {string} icon 부트스트랩 아이콘
 * @param {string} title 아이템 타이틀
 * @param {()=>{}} setTapIndex 인덱스 변경 세터함수
 * @param {number} tapIndex 아이템의 인덱스값
 * @param {boolean} active 액티브된 불린값
 * @returns
 */
export function DropMenu({ children, icon, title, setTapIndex, tapIndex, active }) {
  const handleOnclickIndex = () => {
    setTapIndex(tapIndex);
  };

  return (
    <li onClick={handleOnclickIndex} className={`rounded cursor-pointer p-3`}>
      <div className='d-flex align-items-center justify-content-between'>
        <div className=''>
          <div onClick={handleOnclickIndex} className={`d-flex align-items-center rounded fz-16 font-suit`}>
            <i className={`bi ${icon}`}></i>
            <span className='ms-2'>{title}</span>
          </div>
        </div>
        <div>
          <button className={`btn btn-style-none`}>
            <i className='bi bi-chevron-down'></i>
          </button>
        </div>
      </div>
      <div>
        <ul className={`rounded`} style={{ background: '#ebf1fa' }}>
          {active && children}
        </ul>
      </div>
    </li>
  );
}
