// export const ReportAdminAllScoreItem = ({ title, percent = 0, average_score, denominator, molecular, data }) => {
// molecular={el?.submit_participants}
// denominator={el?.total_participants}
// title={el?.subdivision_subject_name}
// percent={handleNumberGarde(el?.attendance_percent)}

import { ProgressBar } from 'react-bootstrap';
import { handleNumberGarde } from '../data';

// average_score={handleNumberGarde(el?.attendance_percent)}
export const ReportAdminAllScoreItem = ({ data, average }) => {
  return (
    <tr className='fz-14'>
      <th className='text-start'>
        <p className='py-3 fz-14'>{data?.subdivision_subject_name}</p>
      </th>
      <td className='text-center h-full align-center'>
        <div className='d-flex justify-content-center align-items-center py-3 h-full align-middle px-3'>
          <ProgressBar
            variant='info'
            now={data.attendance_percent}
            animated
            className='w-full me-2 progressbar-filter'
          />
          <span className='white-nowrap text-start' style={{ minWidth: '131px' }}>
            <span className='ms-2'>{handleNumberGarde(data.attendance_percent)}&nbsp;%</span>&#40;
            {data?.submit_participants}/{data?.total_participants}&#41;
          </span>
        </div>
      </td>
      <td className='text-center bg-table-teacher-gray'>
        <p className='w-full h-full d-flex justify-content-center align-items-center py-3'>{average}</p>
      </td>
    </tr>
  );
};
