export const reportChartLeftAxis = [0, 25, 50, 75, 100];
export const reportChartENLeftAxis = [0, 15, 30, 45, 60];
export const reportChartColors = ['#D92F8A', '#A0AABA', '#3086EC'];
export const reportChartAdminColors = {
  ko: '#AAD971CC',
  en: '#5A67F3CC',
  social: '#7345CCCC',
  science: '#C5AEFECC',
  math: '#86D9D4CC',
};
export const reportChartTeacherColors = ['#3086EC', '#A0AABA'];
export const reportAdmin100Colors = [
  '#e8dee6',
  '#517142',
  '#77989b',
  '#31c815',
  '#aca037',
  '#57b975',
  '#b1f7a3',
  '#1e3f15',
  '#3053c9',
  '#d2e897',
  '#726ca4',
  '#3128c0',
  '#811c5f',
  '#798113',
  '#95014c',
  '#de1c61',
  '#e48370',
  '#154e82',
  '#611b94',
  '#731e1d',
  '#0d9c60',
  '#1ee300',
  '#9dbeba',
  '#76376b',
  '#f1253f',
  '#bda5ed',
  '#dd499c',
  '#4140e6',
  '#ee2f03',
  '#7e4bcb',
  '#69b16b',
  '#b54ef6',
  '#03def6',
  '#19bb73',
  '#b41ed0',
  '#68c0a1',
  '#ee1fad',
  '#88b731',
  '#1557c4',
  '#b7c0d5',
  '#498629',
  '#7e2b2c',
  '#4fed7a',
  '#201668',
  '#ce46e2',
  '#22001f',
  '#ad094f',
  '#aa59f5',
  '#df396c',
  '#afb2a5',
  '#6b3071',
  '#69c6ca',
  '#44921e',
  '#d26103',
  '#4d555f',
  '#06c081',
  '#bfeee3',
  '#e3696f',
  '#13c8cc',
  '#710dbc',
  '#c59e86',
  '#159983',
  '#c20579',
  '#8e22ae',
  '#1748a4',
  '#3fe3d5',
  '#6887b2',
  '#88a2e7',
  '#8c3d46',
  '#860a80',
  '#6a786a',
  '#6b21a2',
  '#e894b4',
  '#d7b54b',
  '#d5635e',
  '#fbbd92',
  '#3d0f0b',
  '#6c71ea',
  '#879587',
  '#a56f7b',
  '#a219b0',
  '#9f1304',
  '#79343e',
  '#6dffbc',
  '#c77726',
  '#6e65c1',
  '#668045',
  '#6bd4ae',
  '#e100da',
  '#c61b5f',
  '#8e8d5c',
  '#b291bf',
  '#c29c39',
  '#89c955',
  '#4698eb',
  '#e9fefc',
  '#3796f0',
  '#83996a',
  '#e9d179',
  '#aad6fe',
];

export const reportChartKeys = ['나의 점수', '학과 평균', '1학년 평균'];

export const reportChartMargin = { top: 20, right: 20, bottom: 40, left: 40 };
export const reportAdminChartMargin = { top: 20, right: 20, bottom: 40, left: 130 };

export const reportTeacherStudentList = [
  {
    id: 0,
    index: 0,
    classNumber: 123456789,
    name: 'muzi55',
    department: '간호학과',
  },
  {
    id: 1,
    index: 2,
    classNumber: 123456789,
    name: '5546muzi55',
    department: '간호1학과',
  },
  {
    id: 2,
    index: 3,
    classNumber: 123456789,
    name: '78muzi',
    department: '간호2학과',
  },
];
