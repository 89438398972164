import { ProgressBar } from 'react-bootstrap';
import { LecturePassState } from './LecturePassState';

export const ReportLectureItem = ({ title, percent = 0, content, type }) => {
  return (
    <tr className='border-bottom'>
      <td className='p-3 color-table-gary fz-14 ps-0' style={{ width: '15%' }}>
        {title}
      </td>
      <td className='p-3 color-table-sub-gary fz-14'>{content}</td>
      <td className='p-3' style={{ width: '30%' }}>
        <div className='d-flex align-items-center'>
          <ProgressBar
            variant={percent >= 60 && 'info'}
            now={percent}
            animated
            className={`w-full me-2 ${percent >= 60 && 'progressbar-filter'}`}
          />
          <span className='d-sm-block d-none fz-14'>{percent}&nbsp;%</span>
        </div>
      </td>
      <td className='p-3' style={{ width: '15%' }}>
        <div className='d-flex justify-content-center align-items-center'>
          <LecturePassState state={type} content={type} />
        </div>
      </td>
    </tr>
  );
};
