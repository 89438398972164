export function SubjectEmpty() {
  return (
    <tr>
      <td colSpan={4}>
        <div className='py-1'>
          <svg width='104' height='114' viewBox='0 0 104 114' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_1575_89702)'>
              <path
                d='M97.7483 11.7186C94.2733 11.7186 91.4453 9.07662 91.4453 5.83003C91.4453 2.58343 94.2733 -0.0585938 97.7483 -0.0585938C101.223 -0.0585938 104.051 2.58343 104.051 5.83003C104.051 9.07662 101.223 11.7186 97.7483 11.7186ZM97.7483 3.86715C96.5886 3.86715 95.6473 4.74652 95.6473 5.83003C95.6473 6.91353 96.5886 7.7929 97.7483 7.7929C98.9081 7.7929 99.8494 6.91353 99.8494 5.83003C99.8494 4.74652 98.9081 3.86715 97.7483 3.86715Z'
                fill='#E0E2E7'
              />
              <path
                d='M43.8047 111.266L49.7472 105.714L52.7185 108.49L46.776 114.042L43.8047 111.266Z'
                fill='#E0E2E7'
              />
              <path
                d='M60.8906 107.797L63.8619 105.021L69.8045 110.573L66.8332 113.349L60.8906 107.797Z'
                fill='#E0E2E7'
              />
              <path
                d='M6.40459 24.4755H0.101562V20.5497C0.101562 14.5944 5.28265 9.75391 11.6571 9.75391H15.8591V15.6425H11.6571C8.76192 15.6425 6.40459 17.8449 6.40459 20.5497V24.4755Z'
                fill='#E0E2E7'
              />
              <path
                d='M84.1404 24.4755H77.8374V20.5497C77.8374 17.8449 75.48 15.6425 72.5848 15.6425H68.3828V9.75391H72.5848C78.9593 9.75391 84.1404 14.5944 84.1404 20.5497V24.4755Z'
                fill='#E0E2E7'
              />
              <path d='M32.6641 9.75391H51.5732V15.6425H32.6641V9.75391Z' fill='#E0E2E7' />
              <path d='M32.6641 82.3789H43.1691V88.2675H32.6641V82.3789Z' fill='#E0E2E7' />
              <path
                d='M15.8591 88.2684H11.6571C5.28265 88.2684 0.101562 83.428 0.101562 77.4726V73.5469H6.40459V77.4726C6.40459 80.1775 8.76192 82.3798 11.6571 82.3798H15.8591V88.2684Z'
                fill='#E0E2E7'
              />
              <path d='M0.101562 40.1758H6.40459V57.8416H0.101562V40.1758Z' fill='#E0E2E7' />
              <path d='M77.8359 40.1758H84.139V49.9901H77.8359V40.1758Z' fill='#E0E2E7' />
              <path
                d='M57.4621 103.97C55.3695 103.97 53.6719 102.38 53.6719 100.429V46.6621C53.6719 44.7071 55.3737 43.1211 57.4621 43.1211C58.4748 43.1211 59.4244 43.4901 60.143 44.1575L99.7806 81.193C100.491 81.8525 100.898 82.7633 100.898 83.7015C100.898 85.6566 99.1966 87.2426 97.1082 87.2426H76.3418L60.1934 102.887C59.4581 103.582 58.4748 103.97 57.4621 103.97ZM59.9749 52.327V94.6112L72.7322 82.2529C73.3289 81.6798 74.1441 81.3539 75.0013 81.3539H91.0446L59.9749 52.327Z'
                fill='#E0E2E7'
              />
            </g>
            <defs>
              <clipPath id='clip0_1575_89702'>
                <rect width='104' height='114' fill='white' />
              </clipPath>
            </defs>
          </svg>

          <p className='fw-bold --Palette-Gray-70'>수강중인 과목이 없습니다.</p>
        </div>
      </td>
    </tr>
  );
}
