import { useCallback } from 'react';
import { Link } from 'react-router-dom';

/**
 *
 * @param {string} link 이동될 주소
 * @param {string} icon 부트스트랩 아이콘
 * @param {string} title 사이드바 아이템 제목
 * @param {()=>{}} setTapIndex 클릭시 인덱스값 변경되는 setter 함수
 * @param {number} tapIndex 메뉴 아이템의 index값
 * @param {boolean} active 클릭된 boolean값
 * @returns
 */
export function MenuItem({ link, icon, title, setTapIndex, tapIndex, active }) {
  const handleOnclickIndex = useCallback(() => {
    setTapIndex(tapIndex);
  }, []);

  return (
    <li>
      <div>
        <Link to={link}>
          <div
            onClick={handleOnclickIndex}
            className={`d-flex align-items-center px-3 py-3 rounded fz-16 --Palette-DarkGray-80 font-suit ${
              active && 'sidebar-active'
            }`}
          >
            <i className={`bi ${icon}`}></i>
            <span className='ms-2'>{title}</span>
          </div>
        </Link>
      </div>
    </li>
  );
}
