import { CardLayout } from 'components/shared/card/CardLayout';
import { CardSectionTitle } from 'components/shared/card/CardSectionTitle';
import { useAdminReportQuery } from 'hooks/report/useAdminReportQuery';
import { usePDFDownLoad } from 'hooks/report/usePDFDownLoad';
import { useReport } from 'hooks/report/useReport';
import { useReportQuery } from 'hooks/report/useReportQuery';
import { ReportFooter } from 'pages/student/report/common/ReportFooter';
import { ReportHeadTitle } from 'pages/student/report/common/ReportHeadTitle';
import { ReportSelectLayout } from 'pages/student/report/common/ReportSelectLayout';
import { adminAllChart, handleNumberGarde, teacherReportDataChanger } from 'pages/student/report/data';
import {
  reportAdmin100Colors,
  reportChartENLeftAxis,
  reportChartLeftAxis,
  reportChartTeacherColors,
} from 'pages/student/report/reportConstant';
import { ReportStudentChart } from 'pages/student/report/student/ReportStudentChart';
import { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { adminColorState } from 'recoil/report/admin/adminColorState';
import { adminReportState } from 'recoil/report/admin/adminReportState';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { teacherReportState } from 'recoil/report/teacher/teacherReportState';
import { PdfDownButton } from '../common/PdfDownButton';
import { ReportAdminAllScoreItem } from '../student/ReportAdminAllScoreItem';
import { ReportTeacherScoreItem } from '../student/ReportTeacherScoreItem';
import { ReportAdminCard } from './ReportAdminCard';
import { ReportAdminCharts } from './ReportAdminCharts';
import { ReportAdminReligion } from './ReportAdminReligion';

export const ReportAdmin = () => {
  const user = useRecoilValue(loginUser);
  const { semesterSelector, departmentSelector, adminDepartmentSelector } = useReport();
  const [reportState, setReportState] = useRecoilState(studentReportState);
  const [adminReport, setAdminReport] = useRecoilState(adminReportState);
  const [reacherReportState, setReacherReportState] = useRecoilState(teacherReportState);

  const { targetRef, flexRef, liberalRef, handleClickDownPDFAdmin, handleClickDownPDF } = usePDFDownLoad();
  const [adminColors, setAdminColors] = useRecoilState(adminColorState);

  const {
    관리자_전체학과_선택_정보,
    관리자_전체학과_선택_로딩,
    관리자_개별학과선택_정보,
    관리자_개별학과선택_로딩,
    관리자_전체교양_정보,
    관리자_개별교양_정보,
    관리자_전체과목차트_정보,
    관리자_전체과목차트_로딩,
    관리자_개별과목차트_정보,
    관리자_개별과목차트_로딩,
    어드민_두번째_차트_정보,
  } = useAdminReportQuery();

  const { 교수학과_정보, 관리자_전체학과선택_정보, 선생_두번째_차트_정보 } = useReportQuery();
  useEffect(() => {
    if (관리자_전체학과선택_정보?.data)
      setAdminColors(
        관리자_전체학과선택_정보?.data
          ?.filter((el) => el.sub_division_id)
          .map((el, index) => ({ ...el, color: reportAdmin100Colors[index] }))
      );
    return () => {
      setReportState(null);
      setReacherReportState(null);
    };
  }, [관리자_전체학과선택_정보]);

  // console.log(adminAllChart(관리자_전체과목차트_정보?.data));
  return (
    <section>
      <div className='row'>
        <div className='col'>
          <div className='position-relative'>
            {/* 셀렉트 박스 */}
            <ReportSelectLayout>
              <div className='d-flex align-items-center' style={{ width: '60%' }}>
                {semesterSelector(true, setReportState)}
              </div>
              {adminDepartmentSelector(true, setAdminReport)}
            </ReportSelectLayout>

            {/* {true && (
              <div className='card p-3 w-100' style={{ height: '100px' }}>
                <SpinnerLoading />
              </div>
            )} */}
            {adminReport ? (
              <>
                <PdfDownButton
                  onClick={() => {
                    if (adminReport?.value) return handleClickDownPDF();
                    handleClickDownPDFAdmin();
                  }}
                />
                <div ref={targetRef}>
                  {/* 타이틀 섹션 */}
                  <ReportHeadTitle
                    visible={false}
                    title={교수학과_정보?.data?.sub_division_name}
                    name={adminReport?.label}
                    no={'학번'}
                    department={'기본강의 심화'}
                    semesterData={reportState?.label}
                  />
                  {adminReport?.value ? (
                    <ReportAdminCard data={관리자_개별학과선택_정보?.data} />
                  ) : (
                    <ReportAdminCard data={관리자_전체학과_선택_정보?.data} />
                  )}

                  {user?.role === 'ADMIN' && (
                    <CardLayout title='2. 과제수행도'>
                      {/* 차트 섹션 */}
                      <section>
                        <CardSectionTitle index='1' title='기초학습능력진단 검사' />
                        <div className='d-flex overflow-auto'>
                          <div className='w-full'>
                            {adminReport?.value ? (
                              <>
                                {/* 개별 학과 */}
                                <section ref={flexRef} className='d-block d-md-flex'>
                                  {관리자_개별과목차트_정보?.data?.map((item) => (
                                    <div className='w-full d-flex justify-content-center align-items-center mx-5'>
                                      <ReportStudentChart
                                        maxValue={item.statics_subject_name.includes('영어') ? 60 : 100}
                                        LeftAxis={
                                          item.statics_subject_name.includes('영어')
                                            ? reportChartENLeftAxis
                                            : reportChartLeftAxis
                                        }
                                        chartTitle={
                                          item.statics_subject_name.includes('영어')
                                            ? `${item.statics_subject_name} - 60점 만점`
                                            : item.statics_subject_name
                                        }
                                        data={teacherReportDataChanger(item.subdivision_score, item.grade_score)}
                                        colors={reportChartTeacherColors}
                                      />
                                    </div>
                                  ))}
                                </section>
                              </>
                            ) : (
                              <>
                                {/* 전체 학과 */}
                                <div className='px-3' ref={flexRef}>
                                  <ReportAdminCharts
                                    en={관리자_전체과목차트_정보?.data?.english}
                                    ko={관리자_전체과목차트_정보?.data?.korea}
                                    math={관리자_전체과목차트_정보?.data?.math}
                                    science={관리자_전체과목차트_정보?.data?.science}
                                    social={관리자_전체과목차트_정보?.data?.social}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </section>

                      {/* 테이블 */}
                      <div className='my-5'>
                        <Table className='w-full'>
                          <thead>
                            {adminReport?.value ? (
                              <>
                                <tr className='fz-14'>
                                  <th className='text-start align-center' style={{ width: '20%' }}>
                                    <h4 className='py-3'>구분</h4>
                                  </th>
                                  <th className='white-nowrap' style={{ width: '26.6%' }}>
                                    <h4 className='py-3'>참여율 &#40;참여학생 / 학과학생&#41;</h4>
                                  </th>
                                  <th className='bg-table-teacher-gray' style={{ width: '26.6%' }}>
                                    <h4 className='py-3'>
                                      학과 평균 점수 <span>★</span>
                                    </h4>
                                  </th>
                                  <th style={{ width: '26.6%' }}>
                                    <h4 className='py-3'>1학년 평균 점수</h4>
                                  </th>
                                </tr>
                              </>
                            ) : (
                              <tr className='fz-14'>
                                <th className='text-start align-center' style={{ width: '20%' }}>
                                  <h4 className='py-3'>구분</h4>
                                </th>
                                <th className='white-nowrap' style={{ width: '40%' }}>
                                  <h4 className='py-3'>참여율 &#40;참여학생 / 학과학생&#41;</h4>
                                </th>
                                <th className='bg-table-teacher-gray' style={{ width: '40%' }}>
                                  <h4 className='py-3'>
                                    1학년 평균 점수 <span>★</span>
                                  </h4>
                                </th>
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {adminReport?.value ? (
                              <>
                                {어드민_두번째_차트_정보?.data?.map((item) => (
                                  <ReportTeacherScoreItem
                                    title={item.statics_subject_name}
                                    percent={handleNumberGarde(item.attendance_percent)}
                                    average_score={item.subdivision_score}
                                    average_grade_score={item.grade_score}
                                    submit={item.submit_participants}
                                    total={item.total_participants}
                                  />
                                ))}
                              </>
                            ) : (
                              <>
                                {adminAllChart(관리자_전체과목차트_정보?.data)?.map((el) => {
                                  return (
                                    <ReportAdminAllScoreItem
                                      data={el.data}
                                      average={el.average}

                                      // molecular={el?.submit_participants}
                                      // denominator={el?.total_participants}
                                      // title={el?.subdivision_subject_name}
                                      // percent={handleNumberGarde(el?.attendance_percent)}
                                      // average_score={handleNumberGarde(el?.attendance_percent)}
                                    />
                                  );
                                })}
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                      {/* 교양 퍼센트 */}
                      <section>
                        <CardSectionTitle index='2' title='교양 교육' />
                        {adminReport?.value ? (
                          <div ref={liberalRef} className='mt-4 d-block justify-content-evenly d-lg-flex gap-3'>
                            <ReportAdminReligion
                              sexual={관리자_개별교양_정보?.data?.sub_division_sexual_violence_prevent_percent}
                              addiction={관리자_개별교양_정보?.data?.sub_division_addiction_prevent_percent}
                              domestic={관리자_개별교양_정보?.data?.sub_division_domestic_violence_prevent_percent}
                              safety={관리자_개별교양_정보?.data?.sub_division_safety_training_percent}
                            />
                          </div>
                        ) : (
                          <div ref={liberalRef} className='mt-4 d-block justify-content-evenly d-lg-flex gap-3'>
                            <ReportAdminReligion
                              sexual={관리자_전체교양_정보?.data?.sexual_violence_prevent_percent}
                              addiction={관리자_전체교양_정보?.data?.addiction_prevent_percent}
                              domestic={관리자_전체교양_정보?.data?.domestic_violence_prevent_percent}
                              safety={관리자_전체교양_정보?.data?.safety_training_percent}
                            />{' '}
                          </div>
                        )}
                      </section>
                    </CardLayout>
                  )}

                  <ReportFooter />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
