export function NotFoundText({ firstNumber = 5, secondNumber = 0, children }) {
  return (
    <div>
      <h1>
        {firstNumber}
        <div className='not-found-circle d-flex align-items-center justify-content-center'>
          <img src='/assets/img/notFound.svg' alt='나침반모양 이미지' />
        </div>
        {secondNumber}
      </h1>
      <p className='recheck-found'>{children}</p>
    </div>
  );
}
