import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

export const ReportHeadTitle = ({ semesterData, name, visible = true, no = '1234', department = '기본 과목' }) => {
  const user = useRecoilValue(loginUser);
  return (
    <section className='mb-5 mt-3'>
      <div className='py-3 mb-4 border-bottom'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h2 className='fz-25 --Palette-DarkGray-80'>
              <span className='fw-bold'>{name}</span>
              {user?.role === 'STUDENT' && '님의'} KBU 성공전략 성과 보고서
            </h2>
            <p className='mt-2 color-liberal-description fz-14'> {semesterData}</p>
          </div>
          <div>
            <img width={194} height={54} src='/assets/img/logo.png' alt='로고' />
          </div>
        </div>
      </div>
      {visible && (
        <div className='mb-4'>
          <ul className='d-flex'>
            <li className='me-3'>
              <span className='bg-primary text-white px-3 py-2 fz-14 rounded'>학번: {no}</span>
            </li>
            <li>
              <span className='bg-primary text-white px-3 py-2 fz-14 rounded'>학과: {department}</span>
            </li>
          </ul>
        </div>
      )}
    </section>
  );
};
