import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules } from './EditorToolbar';

export default function BoardQuill({ value, onChange, height = 150 }) {
  const handleChange = (e) => {
    onChange(e);
    // const textLength = value.replace(/(<([^>]+)>)/gi, '').length;
  };

  return (
    <>
      <div className='d-flex py-2'>
        <label htmlFor=''>내용</label>
        <div className='w-100'>
          <EditorToolbar />
          <ReactQuill
            value={value}
            onChange={handleChange}
            className='w-100 mb-4 editorResetStyle'
            modules={modules}
            style={{ height }}
          />
        </div>
      </div>
    </>
  );
}
