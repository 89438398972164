import { atom } from 'recoil';

export const adminQueryKeyState = atom({
  key: 'adminQueryKeyState',
  default: {
    role: '',
    currentPage: 1,
    username: '',
  },
});
